import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

import { formatDate } from '@angular/common';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.reducers';
const urlBase = environment.URL_BASE;

@Injectable({
  providedIn: 'root'
})
export class QueryService {

  httpHeaders: HttpHeaders;
  myDate = new Date();
  data: any;
  constructor(private store: Store<AppState>, private http: HttpClient) {
    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });
  }


  getDataStore(): void {
    this.store.select('shareparams').subscribe(r => {
      this.data = r.dataapp;
      this.httpHeaders = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.data.jwt
        // 'Acces-Control-Allow-Credentials': 'true',
      });
    });
  }

  pcsQueryMortgageLoanBalanceRq(data: any, id: any, type: any, lastAccountId: any): Observable<any> {


    this.getDataStore();

    const rqb = {
      'data': {
        'id': data.id,
        'type': 'PCSQUERYMORTGAGELOANBALANCESRQ',
        'attributes': {
          'companyId': data.companyId,
          'userId': data.userId,
          'timestamp': formatDate(this.myDate, 'yyyyMMddHHmmssSS', 'en-US', 'UTC -05:00'),
          'ip': data.ip,
          'appId': data.appId,
          'appModule': data.appModule,
          'param': {
            'documentNumber': id,
            'documentType': type,
            'lastAccountNumber': lastAccountId
          }
        }

      }

    };

    return this.http.post(`${urlBase}/ch-ms-queries-constructor/mortgageloanbalances`, JSON.stringify(rqb), { headers: this.httpHeaders });
  }

  sendData(service: string, data: any): Observable<any> {
    return this.http.post(service, JSON.stringify(data), { headers: this.httpHeaders });
  }

  closeSesion(service:string,data:any):Observable<any>{
    return this.http.post(service,JSON.stringify(data),{ headers: this.httpHeaders });
  }

}

