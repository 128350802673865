import { Directive, HostListener } from '@angular/core';
// let Porthole: any;
@Directive({ selector: '[refreshSession]' })
export class RefreshDirective {
  @HostListener('click', ['$event'])
  refreshSession(event: Event) {
    // const windowProxy = new Porthole.WindowProxy('todo1');
    // windowProxy.post({
    //   'action': 'refreshSession'
    // });
  }
}
