import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
 
@Injectable({
    providedIn: 'root'
})
export class ComponentStatesService {
 
    // declarations
    objResQueryMortgage = new BehaviorSubject<any>(null);
    objResQueryMortgage$ = this.objResQueryMortgage.asObservable();
    // methods
    setObjResQueryMortgage(status: any) { this.objResQueryMortgage.next(status); 
    }
 
}