import { Injectable } from '@angular/core';
import { firstValueFrom, forkJoin, Observable, switchMap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import jsPDF from 'jspdf';
import { FONT_TYPES, PATH_FONTS, SVN_TEXT_PDF } from './pdfTemplate';

@Injectable({
  providedIn: 'root'
})
export class GeneratePdfService {

  pdf!: jsPDF;
  y!: number;
  fontsLoaded = false;


  constructor(private http: HttpClient) {}

  async initializePdf(): Promise<void> {
    this.pdf = new jsPDF({
      format: 'letter',
      unit: 'px',
      hotfixes: ['px_scaling'],
    });
    this.y = SVN_TEXT_PDF.y + 70;

    // Cargar fuentes cada vez que se inicializa el PDF
    await this.createVirtualFonts();
  }

  loadFonts(): Observable<any> {
    return forkJoin([
      this.http.get(PATH_FONTS?.CIB_FONT_SANS_BOLD || '', {
        responseType: 'blob',
      }),
      this.http.get(PATH_FONTS?.CIB_FONT_SANS_LIGHT || '', {
        responseType: 'blob',
      }),
      this.http.get(PATH_FONTS?.OPEN_SANS_REGULAR || '', {
        responseType: 'blob',
      }),
      this.http.get(PATH_FONTS?.OPEN_SANS_SEMIBOLD || '', {
        responseType: 'blob',
      }),
    ]).pipe(
      switchMap((fontsData: any[]) =>
        Promise.all(
          fontsData.map((font) => {
            const reader = new FileReader();
            return new Promise((resolve) => {
              reader.onload = () => resolve(reader.result);
              reader.readAsDataURL(font);
            });
          })
        )
      )
    );
  }

  async createVirtualFonts(): Promise<void> {
    if (!this.fontsLoaded) {
      const fontsData = await firstValueFrom(this.loadFonts());
      const cibBoldFontData = fontsData[FONT_TYPES.CIB_BOLD].split(',')[1];
      this.pdf.addFileToVFS('CIBFontSans-Bold.ttf', cibBoldFontData);
      this.pdf.addFont('CIBFontSans-Bold.ttf', 'CIBFontSans-Bold', 'normal');
      const cibLightFontData = fontsData[FONT_TYPES.CIB_LIGHT].split(',')[1];
      this.pdf.addFileToVFS('CIBFontSans-Light.ttf', cibLightFontData);
      this.pdf.addFont('CIBFontSans-Light.ttf', 'CIBFontSans-Light', 'normal');
      const openSansFontData = fontsData[FONT_TYPES.OPEN_SANS_REGULAR].split(',')[1];
      this.pdf.addFileToVFS('OpenSans-Regular.ttf', openSansFontData);
      this.pdf.addFont('OpenSans-Regular.ttf', 'OpenSans-Regular', 'normal');
      const openSansBoldFontData = fontsData[FONT_TYPES.OPEN_SANS_SEMIBOLD].split(',')[1];
      this.pdf.addFileToVFS('OpenSans-SemiBold.ttf', openSansBoldFontData);
      this.pdf.addFont('OpenSans-SemiBold.ttf', 'OpenSans-SemiBold', 'normal');

      this.fontsLoaded = true; // Marcar que las fuentes han sido cargadas
    }
  }

  async generarPdf(objInfoPdf: any) {
    await this.initializePdf();
    this.fontsLoaded = false;

    const imgBancolombia = new Image();
    imgBancolombia.src = 'assets/images/bancolombia-horizontal.png';

    const imgTrazo = new Image();
    imgTrazo.src = 'assets/images/stroke-12.png';

    this.pdf.addImage(imgBancolombia, 'PNG', 20, 24, 240, 70);
    this.pdf.addImage(imgTrazo, 'PNG', 440, 0, 380, 130);
    this.cargarDatosEnPdf(objInfoPdf);
    this.pdf.save(`Obligacion_${objInfoPdf.numeroObligacion}`);
  }

  async generarPdfPagoProyecto(objInfoPdf: any){
    await this.initializePdf();
    this.fontsLoaded = false;
    const imgBancolombia = new Image();
    imgBancolombia.src = 'assets/images/bancolombia-horizontal.png';
    const imgTrazo = new Image();
    imgTrazo.src = 'assets/images/stroke-12.png';
    this.pdf.addImage(imgBancolombia, 'PNG', 20, 24, 240, 70);
    this.pdf.addImage(imgTrazo, 'PNG', 440, 0, 380, 130);
    this.cargarDatosEnPdfPorProyecto(objInfoPdf);
    this.pdf.save(`Pago_Proyecto_No_${objInfoPdf.projectId}`);

  }

  textoCampoTitulo(text:string, x: number, y:number){
    this.pdf.setFontSize(14);
    this.pdf.setFont('OpenSans-Regular');
    this.pdf.text(text, x, y);
  }

  textoCampoValor(text:string, x: number, y:number){
    this.pdf.setFontSize(18);
    this.pdf.setFont('CIBFontSans-Bold');
    this.pdf.text(text, x, y);
  }

  aumentarPosicionEnY(){
    const space = (18*5);
    this.y = this.y + space;
  }

  setDato(titulo: string, valor: Array<string>,colum:number, y:number, config:{color?:{R:number,G:number, B:number}} = {color:{R:0,G:0,B:0}}){
    const x = (colum === 1)? 32:408;
    let yLocal = y+25;
    this.pdf.setTextColor(0,0,0);
    this.textoCampoTitulo(titulo,x,y);
    if(config.color){
      this.pdf.setTextColor(config.color.R,config.color.G,config.color.B)
    }
    valor.forEach(item=>{
      this.textoCampoValor(item,x,yLocal);
      yLocal+=25;
    })
  }

  cargarDatosEnPdf(objInfoPdf: any){
    let y = SVN_TEXT_PDF.y;
    const colorRojoItem = {color:{R:255,G:0,B:0}};
    this.setDato(`Comprobante No ${objInfoPdf.comprobante}`,[`${objInfoPdf.fecha}`],1,this.y)
    this.aumentarPosicionEnY();
    this.setDato('Nombre del proyecto',[`${objInfoPdf.nombreProyecto}`],1,this.y);
    this.aumentarPosicionEnY();
    this.setDato('Número del proyecto', [`${objInfoPdf.numeroProyecto}`],1,this.y);
    this.aumentarPosicionEnY();
    this.setDato('Obligación a pagar',[`${objInfoPdf.numeroObligacion}`],1,this.y);
    this.setDato('Valor',[`${objInfoPdf.totalPagar}`],2,this.y, colorRojoItem );
    this.aumentarPosicionEnY();
    this.setDato('Cuenta',[`${objInfoPdf.tipoCuenta}`,`${objInfoPdf.numeroCuenta}`], 1,this.y);

  }

  cargarDatosEnPdfPorProyecto(objInfoPdf: any){
    let y = SVN_TEXT_PDF.y;
    const colorRojoItem = {color:{R:255,G:0,B:0}};
    this.setDato(`Comprobante No ${objInfoPdf.comprobante}`,[`${objInfoPdf.fechaYHora}`],1,this.y)
    this.aumentarPosicionEnY();
    this.setDato('Nombre del proyecto',[`${objInfoPdf.proyecto}`],1,this.y);
    this.aumentarPosicionEnY();
    this.setDato('Número del proyecto', [`${objInfoPdf.projectId}`],1,this.y);
    this.aumentarPosicionEnY();
    this.setDato('Cuenta',[`${objInfoPdf.tipoCuenta}`,`${objInfoPdf.numeroCuenta}`], 1,this.y);
    this.setDato('Valor', [`${objInfoPdf.valor}`],2,this.y, colorRojoItem);


  }

}
