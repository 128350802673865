

import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'accountnumber'
})
export class AccountnumberPipe implements PipeTransform {

  // Si el valor es 

  transform(value: string, ...args: unknown[]): string {
    if(value?.length > 11 && args[0] === ' '){
      // remplazar '-' por ' - '
      value = value.replace(/-/g, ' - ');
    }
    if (value?.length === 11) {
      return value.substring(0, 3) + '-' + value.substring(3, 9) + '-' + value.substring(9, 11);
    }

  
    return value;
  }



}
