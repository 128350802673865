import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'formatDateV2'
})
export class FormatDateV2Pipe implements PipeTransform {

  constructor(private datePipe: DatePipe) {}


  valor: string;
  transform(value: string): string | null {
    if(value === null) return;
    this.valor = value.replace(/^(\d{4})(\d{2})(\d{2})$/g,'$1/$2/$3');
    const parsedDate = new Date(this.valor);
    return this.datePipe.transform(parsedDate, 'dd MMM yyyy');;
  }

}
