import { Pipe, PipeTransform } from '@angular/core';
import { parsedValueToCop } from '../../utilities/ParserToCop';


@Pipe({
  name: 'decimalcop'
})
export class DecimalcopPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    return value == '0' || value   ? parsedValueToCop(value) : '0';
  }



}
