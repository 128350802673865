import { Input, Component, OnInit, OnChanges, SimpleChanges } from '@angular/core';

import pdfMake from 'pdfmake/build/pdfmake';

import { GeneratePdfService } from '../services/pdf/generate-pdf.service';



@Component({
  selector: 'app-confirm-payment',
  templateUrl: './confirm-payment.component.html',
  styleUrls: ['./confirm-payment.component.scss']
})
export class ConfirmPaymentComponent implements OnInit, OnChanges {

  @Input() paymentFinal: any;
  myDate = new Date();
  fechaActual = new Date();
  comprobante = null;

  pdfMake: any = null;

  constructor(private pdfService: GeneratePdfService) {
    this.configurarFuenteGeneradorPdf();
   }

  async configurarFuenteGeneradorPdf() {
    const font = await import('pdfmake/build/vfs_fonts');
    this.pdfMake = pdfMake;
    this.pdfMake.vfs = font.pdfMake.vfs;
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void{
    if(changes['paymentFinal'].firstChange){
      this.paymentFinal = changes['paymentFinal'].currentValue;
    } else {
      if(changes['paymentFinal'].currentValue.projectId !== changes['paymentFinal'].previousValue.projectId  ){
        this.paymentFinal = changes['paymentFinal'].currentValue;
      }
    }
  }




  formatNumber(number: string) {
    let numberStr = number.toString();
    if (numberStr.indexOf('.') === -1) {
      // No decimal point, add ",00" for cents
      numberStr += ",00";
    } else {
      // Replace the decimal point with a comma
      numberStr = numberStr.replace('.', ',');
    }
    let parts = numberStr.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return parts.join(",");
  }

  descargarComprobante() {

    const opciones: any = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true
    };
    const fecha = new Date();
    const fechaFormateada = fecha.toLocaleString('es-ES', opciones);

    const consecutivo = (this.paymentFinal.paymentInformation.consecutive !== undefined )?this.paymentFinal.paymentInformation.consecutive:'Sin consecutivo';
    const objPdf = {
      comprobante: `OPC${consecutivo}`,
      titulo: 'Pago por proyecto',
      fechaYHora: fechaFormateada,
      direccionIp: this.paymentFinal.ip,
      proyecto: this.paymentFinal.name_project_selected,
      valor: this.formatNumber(this.paymentFinal.amount),
      tituloCuenta: this.paymentFinal.tittleAccount,
      numeroCuenta: this.paymentFinal.account,
      tipoCuenta: this.paymentFinal.type,
      projectId: this.paymentFinal.projectId
    }
    this.pdfService.generarPdfPagoProyecto(objPdf);
  }

}







