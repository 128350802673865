import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentsRoutingModule } from './payments-routing.module';
import { PaymentDetailsComponent } from '../payments/payment-details/payment-details.component';
import { AppMaterialModule } from '../shared/app-material.module';
import { BcModule } from '../shared/bc/bc.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VerifyDataComponent } from './verify-data/verify-data.component';
import { PipesModule } from '../shared/pipes/pipes.module';
import { DirectivesModule } from '../shared/directives/directives.module';
import { Store } from '@ngrx/store';
import { ConfirmPaymentComponent } from './confirm-payment/confirm-payment.component';
import { LoaderBlurModule } from '../generic-components/loader-blur/module/loader-blur.module';
import { BcCardSelectModule } from '@bancolombia/design-system-web/bc-card-select';
import { BcRadioModule } from '@bancolombia/design-system-web/bc-radio';
import { BcInputModule } from '@bancolombia/design-system-web/bc-input';
import { BcIconModule } from '@bancolombia/design-system-web/bc-icon';
import { BcIllustrationModule } from '@bancolombia/design-system-web/bc-illustration';
import { BcPictogramModule } from '@bancolombia/design-system-web/bc-pictogram';
import { ErrorGeneralComponent } from './errorGeneral/error-general/error-general.component';
import { BcInlineAlertModule } from '@bancolombia/design-system-web/bc-inline-alert';
import { PaymentOperationDetailsComponent } from './payment-operation/payment-operation-details/payment-operation-details.component';

import {BcShortcutModule} from '@bancolombia/design-system-web/bc-shortcut'
import {BcCheckboxModule} from '@bancolombia/design-system-web/bc-checkbox';
import { VerifyObligationPaymentComponent } from './payment-operation/verifyPayment/verify-obligation-payment/verify-obligation-payment.component';
import { ConfirmPaymentObligationComponent } from './payment-operation/confirmPayment/confirm-payment-obligation/confirm-payment-obligation.component'


@NgModule({
  imports: [
    CommonModule,
    PaymentsRoutingModule,
    AppMaterialModule,
    BcModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    DirectivesModule,
    LoaderBlurModule,
    BcCardSelectModule,
    BcRadioModule,
    BcInputModule,
    BcShortcutModule,
    BcInlineAlertModule,
    BcCheckboxModule,
    BcIconModule.forRoot({
      path:'https://library-sdb.apps.bancolombia.com/bds/6.31.1'
      // path: 'https://galatea-dev.apps.ambientesbc.com/2.0.6'
    }),
    BcIllustrationModule.forRoot({ path: 'https://library-sdb.apps.bancolombia.com/bds/6.31.1' }),
    BcPictogramModule.forRoot({ path: 'https://library-sdb.apps.bancolombia.com/bds/6.31.1'}),
  ],
  declarations: [
    PaymentDetailsComponent,
    VerifyDataComponent,
    ConfirmPaymentComponent,
    ErrorGeneralComponent,
    PaymentOperationDetailsComponent,
    VerifyObligationPaymentComponent,
    ConfirmPaymentObligationComponent
  ],
  providers:[Store],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class PaymentsModule { }
