import * as fromQueryReducer from './store/reducers/query.reducer';
import * as fromParamsReducer from './store/reducers/shareparam.reducer';
import { ActionReducerMap } from '@ngrx/store';
import { Params } from './store/actions/shareparam.action';

export interface AppState {
    queryState: fromQueryReducer.State;
    shareparams: Params;
}


export const AppReducers: ActionReducerMap<AppState> = {
        queryState: fromQueryReducer.reducer,
        shareparams: fromParamsReducer.paramsreducer
};
