import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { Store } from "@ngrx/store";
import { AppState } from "../../app.reducers";
import { codeErrors } from "../../shared/constants";
import { GetItemsQuery } from '../../store/actions/query.actions';
import { CompaniesBalance } from '../../shared/models/campanies.model';

@Component({
  selector: "app-notify",
  templateUrl: "./notify.component.html",
  styleUrls: ["./notify.component.scss"],
})
export class NotifyComponent implements OnInit {
  showerrorexeption = false;
  showbussinessexception = false;
  showTryException : boolean = false;
  msgerror: any;
  errorRelatedCompanies = false;
  tryCounter : number = 0;
  _campaniesBalance: Array<CompaniesBalance> = new Array();
  shareparams : any;
  titleMsg : string = 'El sistema presenta problemas';
  showHandIcon : boolean = false;
  flagInit : boolean = false;
  showPowerIcon : boolean = true;
  showGearIcon : boolean = false;
  showErrorIcon : boolean = false;
  msgAdditional : string = '';
  subMsg : string = '';
  viewBtnGo : boolean = false;
  loading : boolean = false;

  constructor(public store: Store<AppState>, private router: Router,) {}

  ngOnInit() {
    this.store.subscribe((r) => {
      const shareparams: any = r.shareparams;
      const query: any = r.queryState;
      this.shareparams = shareparams;

      if(query.query?.length > 0 && this.flagInit){
        this.router.navigate(['consulta']);
        return;
      }

      if( query.message.status === codeErrors.code406 ){
        this.errorRelatedCompanies = true;
        this.showerrorexeption = false;
        this.showbussinessexception = false;
        this.getMsgError(shareparams, query);
        return;
      }

      if (shareparams.dataapp.status === codeErrors.code406) {
            this.msgerror = shareparams.dataapp.error
              ? shareparams.dataapp.error.errors[0].detail
              : query.message.error
              ? query.message.error.errors[0].detail
              : "BC: No es posible continuar con la transacción en este momento.";
            this.showerrorexeption = false;
            this.showbussinessexception = true;
            this.showTryException = false;
            this.loading = false;
      } else if (query.message.status === codeErrors.code500){
            this.loading = false;
            this.tryCounter += 1;
            this.msgerror = shareparams.dataapp.error
              ? shareparams.dataapp.error.errors[0].detail
              : query.message.error
              ? query.message.error.errors[0].detail
              : "BC: En este momento el sistema presenta problemas intentar nuevamente";
            this.subMsg = 'Intenta más tarde ó comunícate con tu gerente de relación';
            this.getMsgTitle(shareparams,query);
            this.validationRender();
      } else if(shareparams.dataapp.length > 0 ) {
            this.msgerror = shareparams.dataapp.length > 0
              ? shareparams.dataapp
              :  "BC: No es posible continuar con la transacción en este momento.";
              this.subMsg = 'Intenta más tarde ó comunícate con tu gerente de relación';
            this.showerrorexeption = true;
            this.showbussinessexception = false;
            this.showTryException = false;
            this.loading = false;
      } else if (shareparams.dataapp.status !== codeErrors.code406 &&
                 shareparams.dataapp.status !== undefined ||
                 query.message?.error?.errors[0]?.status !== codeErrors.code406 &&
                 query.message?.error?.errors[0]?.status !== undefined ||
                 !this.flagInit)
            {

            const nameService = this.validationIconRender(shareparams, query);
            this.getMsgTitle(shareparams,query, nameService);
            this.getMsgError(shareparams, query, nameService);
            this.showerrorexeption = true;
            this.showbussinessexception = false;
            this.showTryException = false;
            this.loading = false;
            }
      this.flagInit = true;
    });
  }

  getMsgError(shareparams, query, nameService : string = ''): void {
    if(nameService === 'creditpayments') {
      this.msgerror = shareparams?.dataapp?.error
      ? shareparams?.dataapp?.error?.detail
      : query?.message?.error
      ? query?.message?.error?.detail
      : "BC: No es posible continuar con la transacción en este momento.";
    } else {
      this.msgerror = shareparams?.dataapp?.error
      ? shareparams?.dataapp?.error?.errors[0]?.detail
      : query?.message?.error
      ? query?.message?.error?.errors[0]?.detail
      : "BC: No es posible continuar con la transacción en este momento.";
    }
  }

  getMsgTitle(shareparams, query, nameService : string = '') : void {
    if(nameService === 'creditpayments') {
      this.titleMsg = (shareparams.dataapp?.error.title !== undefined)
        ? shareparams?.dataapp?.error?.title
        : query?.message?.error
        ? query?.message?.error?.title
        : "El sistema presenta problemas";
       this.titleMsg = this.tryCounter === 4 ? 'El sistema presenta problemas' : this.titleMsg;
    } else  {
      this.titleMsg = (shareparams.dataapp?.error?.errors[0].title !== undefined)
        ? shareparams?.dataapp?.error?.errors[0]?.title
        : query?.message?.error
        ? query?.message?.error?.errors[0]?.title
        : "El sistema presenta problemas";
       this.titleMsg = this.tryCounter === 4 ? 'El sistema presenta problemas' : this.titleMsg;
    }
  }

  validationNameService(sentence) : string {
    let nameService : string = '';
    const urlApi : string[] = [ 'authorization','relatedcompanies',
                                'mortgageloanbalances','obligations',
                                'movements','creditpayments' ];

    for (let i = 0; i < urlApi.length; i++) {
      if(sentence.includes(urlApi[i])){
        nameService = urlApi[i];
        break;
      }
    }

    return nameService;
  }

  validationIconRender(shareparams, query) : string {
    try {
      this.showPowerIcon = false;
      const msgAdd : string = ' más tarde.'
      const sentence = shareparams.dataapp?.url
                        ? shareparams.dataapp?.url : query.message.url;
      const nameService : string = this.validationNameService(sentence);
      let code, status;

      if(nameService === 'creditpayments') {
         code = query.message.error?.code
                      ? query.message.error?.code : shareparams.dataapp.error?.code;
         status = query.message.error?.status
                        ? query.message.error?.status : shareparams.dataapp.error?.status;
      } else {
           code = query.message.error?.errors[0]?.code
                        ? query.message.error?.errors[0]?.code : shareparams.dataapp.error?.errors[0]?.code;
           status = query.message.error?.errors[0]?.status
                        ? query.message.error?.errors[0]?.status : shareparams.dataapp.error?.errors[0]?.status;
      }

        if(nameService === 'relatedcompanies') {
          if (status === codeErrors.code400 && code === 2){
            this.showHandIcon = true;
            this.subMsg = ' Comunícate con tu gerente de relación.'
          }
        } else if (nameService === 'obligations' || nameService === 'movements'){
          if(status === codeErrors.code500 && (code === 1 || code === 2)){
            this.showErrorIcon = true;
            this.msgAdditional = msgAdd;
          } else if (status === codeErrors.code401 && code === 998){
            this.showErrorIcon = true;
          } else if (status === codeErrors.code500 && code === 999){
            this.showErrorIcon = true;
          }
        } else if (nameService === 'mortgageloanbalances'){
          if(status === codeErrors.code500 && code === 2){
              this.msgAdditional = msgAdd;
              this.subMsg = 'Ingrese más tarde ó contáctanos por alguno de nuestros canales de atención';
          } else if (status === codeErrors.code500 && code === 3) {
              this.showGearIcon = true;
          }
        } else if (nameService === 'creditpayments') {
          if(status === codeErrors.code500 && (code === 2 || code === 999)){
              this.showErrorIcon = true;
              this.viewBtnGo = true;
          }
        }
        this.showPowerIcon = (this.showGearIcon == false && this.showHandIcon == false && this.showErrorIcon === false)
                              ? true : false;
        return nameService;
    } catch (_) {

    }
  }

  validationRender(): void {
     if(this.tryCounter === 4){
          this.showTryException = false;
          this.showerrorexeption = true;
      }else{
          this.showTryException = true;
          this.showerrorexeption = false;
          this.showbussinessexception = false;
      }
  }

  tryRequest() : void {
    if(this.tryCounter > 0 && this.tryCounter <= 3){
        this.loading = true;
        const dataShareparams : any = {
              appId: this.shareparams?.dataapp.appId,
              appModule:   this.shareparams?.dataapp.appModule,
              companyId:  this.shareparams?.dataapp.companyId,
              companyname: this.shareparams?.dataapp.companyname,
              docId:  this.shareparams?.dataapp.docId,
              docType:  this.shareparams?.dataapp.docType,
              id:  this.shareparams?.dataapp.id,
              ip:  this.shareparams?.dataapp.ip,
              jwt:  this.shareparams?.dataapp.jwt,
              rthtoken:  this.shareparams?.dataapp.rthtoken,
              userId:  this.shareparams?.dataapp.userId
        }
        this.store.dispatch(new GetItemsQuery(this._campaniesBalance, dataShareparams));
    }
  }

}
