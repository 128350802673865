import {CompaniesBalance} from '../../shared/models/campanies.model';
import { All, QueryActionTypes } from './../actions/query.actions';


export interface State {
    query: Array<CompaniesBalance>;
    message: any;
}

export const initialState: State = {
    query: new Array(),
    message: '*****Initial Statement'
};

export function reducer(state =  initialState, actions: All): State {
    switch (actions.type) {
        case QueryActionTypes.LIST_ITEMS_SUCCESS :
        case QueryActionTypes.GET_ITEMS : {
            return {
                ...state,
                query: actions._item,
                message: '*****State saved'
            };
        }
        case QueryActionTypes.EMPTY_ITEMS :
        {
            return {
                ...state,
                message: actions._error
            };
        }
         default:
            return {
                ...state
            };
    }

}
