import { Directive, HostListener } from '@angular/core';

// let Porthole: any;
@Directive({
    selector: '[finishOption001]'
})
export class FinishOption003Directive {
    @HostListener('click', ['$event'])
    finishOption(event: Event) {
        // const windowProxy = new Porthole.WindowProxy('todo1');
        // windowProxy.post({
        //     'action': 'opt003Finished'
        // });

    }
}
