import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';

@Component({
  selector: 'app-popup-info',
  templateUrl: './popup-info.component.html',
  styleUrls: ['./popup-info.component.css']
})
export class PopupInfoComponent implements OnInit {
  letter = true;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.validateInfoComponent();
  }

  public validateInfoComponent() {    
    if (this.data.dataKey == 'movements') {
      this.letter = false;      
    } else {
      this.letter = true;
    }
  }
}
