import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

import { formatDate } from '@angular/common';
const urlBase = environment.URL_BASE;
@Injectable({
  providedIn: 'root'
})
export class NitserviceService {

  httpHeaders: HttpHeaders;
  myDate = new Date();
  data: any;

  constructor(private http: HttpClient) {
  }

  async getDatajwt(dataapp) {
      this.data = dataapp;
      this.httpHeaders = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.data.jwt
      });
  }


  pcsQueryRelatedCompaniesRq(data: any): Observable<any> {
    this.getDatajwt(data);
    const rq = {
      'data': {
          'type': 'PCSQUERYRELATEDCOMPANIESRQ',
          'id': data.id,
          'attributes': {
              'companyId': data.companyId,
              'userId': data.userId,
              'timestamp': formatDate(this.myDate, 'yyyyMMddHHmmssSS' , 'en-US', 'UTC -05:00'),
              'ip': data.ip,
              'appId': data.appId,
              'appModule': data.appModule,
              'param': {
                   'documentType': data.docType,
                   'name': data.companyname,
                   'documentNumber': data.companyId
              }
          }
      }
  };
    return this.http.post(`${urlBase}/ch-ms-queries-constructor/relatedcompanies`, JSON.stringify(rq), {headers: this.httpHeaders});

  }
}
