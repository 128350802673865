import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QueryBalanceComponent } from './components/query-balance/query-balance.component';
import { GenericModule } from '../generic-components/generic.module';
import { QueryRoutingModule } from './query-routing.module';



@NgModule({
  imports: [
    CommonModule,
    GenericModule,
    QueryRoutingModule,

  ],
  declarations: [
    QueryBalanceComponent,
  ],
  exports: [
    QueryBalanceComponent
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA ]
})
export class QueryModule { }
