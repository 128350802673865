import { NgModule } from '@angular/core';
import { CurrencyFormatPipe } from './currency/currency-format.pipe';
import { ReactiveCurrencyFormatPipe } from './reactive-currency-format.pipe';
import { DecimalcopPipe } from './decimalcop/decimalcop.pipe';
import { AccountnumberPipe } from './accountNumber/accountnumber.pipe';
import { FormatDateV2Pipe } from './format/format-date-v2.pipe';

@NgModule({
  declarations: [
    CurrencyFormatPipe,
    ReactiveCurrencyFormatPipe,
    DecimalcopPipe,
    AccountnumberPipe,
    FormatDateV2Pipe
  ],
  exports: [
    CurrencyFormatPipe,
    ReactiveCurrencyFormatPipe,
    DecimalcopPipe,
    AccountnumberPipe,
    FormatDateV2Pipe
  ]
})
export class PipesModule { }
