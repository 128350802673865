import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";


import { Store } from "@ngrx/store";

import { QueryService } from "../../query/services/query.service";


import { Router } from "@angular/router";
import { AccountDetails } from '../../shared/models/accountDetails';
import { PaymentsService } from '../services/payments.service';
import { PaymentData } from "../../shared/models/payment";

import { BcCardProductConfig, BcCardType, BcFooterButtonConfig } from "@bancolombia/design-system-web/bc-behaviors";
import { BcCardSelectComponent } from '@bancolombia/design-system-web/bc-card-select';
import { AccountnumberPipe } from "../../shared/pipes/accountNumber/accountnumber.pipe";
import { parsedValueToCop } from "../../shared/utilities/ParserToCop";
import { BcDialogRef, BcDialogService } from "@bancolombia/design-system-web/bc-services";
import { BcAlertComponent } from "@bancolombia/design-system-web/bc-alert";

export const MAX_TRY = 3;
export const TEXT_SELECCIONE_PROYECTO = "Seleccione un proyecto a pagar";
export const PROJECT_EMPTY = {
  tittle: 'Prueba yamid',
  value: '',
  cardType: BcCardType['none-icon'],
  subtittle: '',
  projectId: '',
  projectName: 'Seleccione un proyecto a pagar',
  ownerName: null,
  quarterlyInterest: {
    amount: null,
    currencyCode: null
  },
  pendingBalance: {
    amount: null,
    currencyCode: null
  },
  approvedAmount: null,
  dueDate: null,
  nextPaymentDate: null,
  capitalBalance: null,
  currentInterestBalance: null,
  arrearsInterestBalance: null,
};

export const accountSubtype = {
  1: 'Corriente - Bancolombia',
  7: 'Ahorros - Bancolombia',
  9: 'Depósito electrónico - Bancolombia',
  'CUENTA_AHORRO': 'Ahorros - Bancolombia',
  'CUENTA_CORRIENTE': 'Corriente - Bancolombia',
  'CUENTA_DEPOSITO': 'Depósito electrónico - Bancolombia',
};

@Component({
  selector: "app-payment-details",
  templateUrl: "./payment-details.component.html",
  styleUrls: ["./payment-details.component.scss"],

})
export class PaymentDetailsComponent implements OnInit/*, AfterViewInit */ {



  stepsArray: string[] = ["Datos del pago", "Verificar"];
  direction: string = "horizontal";
  indexPosition = 0;
  projects: Array<AccountDetails> = [];
  projectList: AccountDetails[] = [];
  projectsArr: AccountDetails[] = [];
  isDataPayment = true;
  isVerifyData = false;
  cont = 0;
  queryloadMoreData = false;
  lastId: any = '';
  select: HTMLElement;
  content: HTMLElement | '';
  paymenFrm: UntypedFormGroup;
  isLessData = false;
  accountDetails = new AccountDetails();
  isSelect = false;
  isContent = false;
  otherAmount: string;
  accountValue = '';
  itemSelect: any;
  otherAmountFiel: any;
  isModalError = false;
  statusValidate = false;
  paymentModel = new PaymentData();
  dataParent: any;
  stateStore: any;
  countTryValidatePayment = 0;
  isDefinitiveModalError = false;

  showOtherAmount = false;
  statusOtherAmount = 'default';
  showErrorOtherAmount = false;
  defaultValueCardSelectProject;

  accountList: Array<BcCardProductConfig>;
  resultAccountList = [];
  resultMoreAccounts;
  configDropDown: BcFooterButtonConfig = {
    text: 'Ver mas'
  }

  codigoError: string = '';
  ocurrioUnError: boolean = false;
  ocurrioUnErrorListarCuentas: boolean = false;
  numeroIntentosSeleccionProyecto: number = 0;
  numeroIntentosListarCuentas: number = 0;

  mostrarErrorCuentasNoDisponibles: boolean = false;
  deshabilitarBotonSaldoInsuficiente = false;
  typeOfPayment: string;

  indexPositionSelectedAccount;
  loader:boolean = false;
  showAlert:boolean = false;
  dialogRef: BcDialogRef;
  mostrarAlertaPagoAnterior: boolean = true;

  paginaListarCuentas = 1;
  numeroRegistrosListarPaginas = 30;
  accounts=[];

  @ViewChild('cardSelectProyecto') cardSelectProyecto: BcCardSelectComponent;
  @ViewChild('cardSelect') cardSelect: BcCardSelectComponent;


  constructor(
    public queryService: QueryService,
    public store: Store<{ shareparams: any }>,
    public router: Router,
    public fb: UntypedFormBuilder,
    public paymentService: PaymentsService,
    private changeDetectorRef: ChangeDetectorRef,
    private dialogService: BcDialogService
  ) {

  }


  ngOnInit(): void {
    this.getStateStore();
    this.paymentFrmGroup();
    this.getAllProjects();
    this.changeValueOtherAmount();
    this.getAllAccountList();
  }

  public getStateStore() {
    this.store.select('shareparams').subscribe((state: any) => {
      if (state) {
        this.stateStore = state;
      }
    });
  }

  paymentFrmGroup() {
    this.paymenFrm = this.fb.group({
      projectSelect: ['', [Validators.required]],
      amount: ['1', [Validators.required]],
      otherAmount: [''],
      account: ['', [Validators.required]],
      typeAccount: [''],
      titleAccount: [''],
      totalPayment: [''],
      projectName: ['']
    })
  }

  public setValueProject(project: AccountDetails) {

    this.paymenFrm.patchValue({
      id: project.projectId,
      totalPayment: this.accountDetails.pendingBalance.amount,
      projectName: this.accountDetails.projectName
    }, {
      emitEvent: false
    });
  }

  public getAllProjects() {
    let projects = this.paymentService.getDataProjects();
    const moreProjects = this.paymentService.getPendingInfoMoreProjects;


    projects.forEach(elemento => {
      if (this.paymentService.projectId === elemento.projectId) {
        this.accountDetails = elemento;
        this.setValueProject(elemento)
      }
    })

    projects = projects.map(project => ({
      ...project,
      tittle: project.projectName,
      subtittle: project.projectId,
      cardType: BcCardType['none-icon'],
      value: project.projectId
    })) as any;
    this.projectList = projects;
    this.lastId = this.projectList[this.projectList.length - 1].projectId;
    this.defaultValueCardSelectProject = this.paymentService.projectId;
    if(moreProjects === 'N'){
      this.updateDropdownConfiguration(0);
    }

  }

  changeValueOtherAmount() {
    this.paymenFrm.get('otherAmount')?.valueChanges.subscribe((value) => {
      const convertOtherAmount = parseFloat(value.replace(/\./g, ''));
      const convertTotalPayment = parseFloat(this.paymenFrm.value.totalPayment);
      this.statusOtherAmount = (convertOtherAmount > convertTotalPayment) ? 'error' : 'default';
      this.showErrorOtherAmount = this.statusOtherAmount === 'error' ? true : false;
      this.updateListAccount();
    })
  }

  getAllAccountList(verMas?: string) {
    if(verMas === undefined){
      this.getAccounst();
    } else {
      this.paginaListarCuentas ++;
      this.getAccounst();
    }

  }

  getAccounst() {
    this.loader = true;
    if(this.resultAccountList.length > 0){
      this.accounts = this.resultAccountList;
    }
    this.paymentService.getAccountList(this.paginaListarCuentas, this.numeroRegistrosListarPaginas).subscribe(response => {
      this.ocurrioUnErrorListarCuentas = false;
      this.resultAccountList = response.data.attributes.result.accountList;
      this.resultMoreAccounts = response.data.attributes.result.flagMoreRecords;
      if (!this.resultMoreAccounts) {
        if(this.accounts.length > 0){
          this.resultAccountList = this.resultAccountList.concat(this.accounts);
        }
        this.updateDropdownConfiguration(1)
      }
      this.createDataAccountList(this.resultAccountList);
      this.loader = false;
    }, (error) => {

      if (error.status === 404) {
        this.codigoError = error.error.errors[0].code;
        if (this.codigoError === 'CTB003') {
          this.mostrarErrorCuentasNoDisponibles = true;
        } else {
          this.ocurrioUnErrorListarCuentas = true;
        }
      } else {
        this.codigoError = error.error.errors[0].code;
        this.ocurrioUnErrorListarCuentas = true;
      }
      this.loader = false;
    })


  }



  createDataAccountList(response: any) {
      setTimeout(() => {
        this.accountList = response.map((account: any) => {
          return {
            tittle: account.customName,
            subtittle: [this.getSubtype(account.type), new AccountnumberPipe().transform(account.accountNumber)],
            value: new AccountnumberPipe().transform(account.accountNumber),
            cardType: BcCardType['none-icon'],
            balance: `COP ${parsedValueToCop(account.balance + '')}`,
            statusText: 'Saldo disponible',
            statusType: this.isAccountSufficient(account) ? null : 'custom-warning',
            additionalText: this.isAccountSufficient(account) ? null : 'Esta cuenta no tiene fondos suficientes para realizar este pago',
            disabled: true,
            aqdivider: true,
          } as any;
        });
      }, 0);
  }

  getSubtype(account: any) {
    switch (account) {
      case 'CUENTA_CORRIENTE':
        return 'Cuenta corriente';
      case 'CUENTA_DE_AHORRO':
        return 'Cuenta de ahorros';
      default:
        break;
    }
  }

  isAccountSufficient(account: any): boolean {
    const accountBalance = parseFloat(account.balance || '0');
    this.amountSelected()
    return accountBalance >= parseFloat(this.paymenFrm.value.amount || '0');
  }

  /**
   * this.typeOfPayment --> es para armar la peticion de pago:
   * (Solo se permiten las letras P->Pago total, I -> Pago cuota y U -> otro valor);
   */
  amountSelected() {
    switch (this.paymenFrm.value.amount) {
      case '1':
        this.paymenFrm.value.amount = this.accountDetails.quarterlyInterest.amount;
        this.typeOfPayment = 'I';
        break;
      case '2':
        this.paymenFrm.value.amount = this.accountDetails.pendingBalance.amount;
        this.typeOfPayment = 'P';
        break;
      case '3':
        const otherAmountConvert = parseFloat(this.paymenFrm.value.otherAmount.replace(/\./g, ''));
        this.typeOfPayment = 'U';
        this.paymenFrm.value.amount = otherAmountConvert;
        break;
    }
  }

  public updateListAccount(): void {
    this.changeDetectorRef.markForCheck();
    this.accountList = [];
    this.createDataAccountList(this.resultAccountList);
    setTimeout(() => {
      this.refreshDropdownComponent();
    }, 0);

  }

  //refrescar el componente de listar cuentas
  private refreshDropdownComponent(): void {
    const defaultValue = this.paymenFrm.get('account').value!;
    if (defaultValue !== "") {
      this.cardSelect?.updateDropdownValues(this.accountList);
      const defaultIndex = this.getDropdownDefaultIndex(defaultValue);
      this.cardSelect?.bcCardSelectBehavior?.markItemByIndex(defaultIndex);
      const lis = document.querySelectorAll('#selector-account li');
      lis[defaultIndex].dispatchEvent(new Event('click'));
      this.changeSelectAccountList(this.cardSelect.values[defaultIndex]);
    }

  }

  private getDropdownDefaultIndex(defaultValue: string) {
    return (this.cardSelect?.values as { subtittle: string[] }[])?.findIndex((item: any) => item.subtittle[1] === defaultValue);
  }
  // Para ocultar o mostrar el ver mas en listar cuentas
  public updateDropdownConfiguration(cardSelect: number): void {
    setTimeout(() => {
      switch (cardSelect) {
        case 0:
          const dropdownFooterProjects = document.getElementsByClassName('bc-card-select-content-footer')[0];
          if (dropdownFooterProjects) {
            dropdownFooterProjects.classList.add('bc-hidden')
          }
          break;
        case 1:
          const dropdownFooterAccountList = document.getElementsByClassName('bc-card-select-content-footer')[1];
          if (dropdownFooterAccountList) {
            dropdownFooterAccountList.classList.add('bc-hidden')
          }
          break;
      }
    }, 10);
  }


  // Modificado

  public getMorgageBalance() {
    this.loader = true;
    const pendingInfoMoreProjects = this.paymentService.getPendingInfoMoreProjects;
    if (pendingInfoMoreProjects === 'Y') {
      this.queryService.pcsQueryMortgageLoanBalanceRq(this.stateStore.dataapp, this.stateStore.id, this.stateStore.type, this.lastId)
        .subscribe((response: any) => {
          this.ocurrioUnError = false;
          const { accountDetails, pendingInfo } = response.data.attributes.param;
          let projectsSeeMore = accountDetails;
          this.paymentService.saveRes(pendingInfo);
          this.lastId = accountDetails[accountDetails.length - 1].projectId;
          this.defaultValueCardSelectProject = this.paymentService.projectId;

          projectsSeeMore = projectsSeeMore.map(project => ({
            ...project,
            tittle: project.projectName,
            subtittle: project.projectId,
            cardType: BcCardType['none-icon'],
            value: project.projectId
          }));

          this.projectList = this.projectList.concat(projectsSeeMore);
          this.loader = false;

        },
          (error: any) => {
            this.ocurrioUnError = true;
            this.loader = false;
          });
    }else{
      this.loader = false;
      this.updateDropdownConfiguration(0);
    }


  }

  showSelected(value: any) {
    switch (value) {
      case '1':
        if(this.paymenFrm.get('account').value !== ""){
          this.updateListAccount();
        }
        if(this.paymenFrm.get('otherAmount').value !== ""){
          this.paymenFrm.get('otherAmount').setValue('');
        }
        this.showOtherAmount = false;
        this.showAlert = true;
        break;
      case '2':
        this.showAlert = false;
        this.showOtherAmount = false;
        this.updateListAccount();
        if(this.paymenFrm.get('otherAmount').value !== ""){
          this.paymenFrm.get('otherAmount').setValue('');
        }
        break;
      case '3':
        this.showAlert = false;
        this.showOtherAmount = true;
        break;
    }
  }

  validateSecondPage(idProjectCredit: string){
    this.paymentService.validateSecondPayment(idProjectCredit).subscribe((response: any)=>{
      this.dialogRef?.close(null);
      if(!response.data.attributes.result.canBePaid){
        this.mostrarAlertaPagoAnterior = false;
        this.openAlertPayment();
      }else{
        this.mostrarAlertaPagoAnterior = true;
      }
    })
  }

  changeSelect(project: AccountDetails) {

    this.validateSecondPage(project.projectId);

    if (this.accountDetails.projectId !== project.projectId) {
      this.accountDetails = project;
      this.setValueProject(this.accountDetails);
      this.updateListAccount();
    }else{
      this.setValueProject(this.accountDetails);
    }


  }

  changeSelectAccountList(account: any) {
    this.deshabilitarBotonSaldoInsuficiente = false;
    this.paymenFrm.patchValue({
      typeAccount: `${account.subtittle[0]}`,
      titleAccount: `${account.tittle}`
    }, {
      emitEvent: false
    });

    if (account.value === this.paymenFrm.value.account) {
      if (account.additionalText === 'Esta cuenta no tiene fondos suficientes para realizar este pago') {
        this.deshabilitarBotonSaldoInsuficiente = true;
      }
      return;
    }else{
      this.paymenFrm.patchValue({
        typeAccount: `${account.subtittle[0]}`,
        titleAccount: `${account.tittle}`,
        account: `${account.value}`
      }, {
        emitEvent: false
      });

      if (account.additionalText === 'Esta cuenta no tiene fondos suficientes para realizar este pago') {
        this.deshabilitarBotonSaldoInsuficiente = true;
      }
    }



  }

  convertAccountType(typeAccount: string): string{
    switch (typeAccount) {
      case 'Cuenta corriente':
        return 'CUENTA_CORRIENTE';
      case 'Cuenta de ahorros':
        return 'CUENTA_DE_AHORRO';
    }
  }

  verifyData() {
    this.isDataPayment = false;
    this.isVerifyData = true;
    this.amountSelected();
    this.dataParent = {};
    this.dataParent = {
      'projectId': this.paymenFrm.value.projectSelect,
      'name_project_selected': this.paymenFrm.value.projectName,
      'amount': this.paymenFrm.value.amount,
      'type': this.convertAccountType(this.paymenFrm.value.typeAccount),
      'account': this.paymenFrm.value.account.replace(/[\s-]/g, ''),
      'tittleAccount': this.paymenFrm.value.titleAccount,
      'typeOfPayment': this.typeOfPayment
    }
  }


  returnInfo(event){
    this.isVerifyData = event;
    this.isDataPayment = true;
  }

  openAlertPayment(): void {

    this.dialogRef = this.dialogService.open(BcAlertComponent, {
      id: 'MyAlertId',
      type: 'warning',
      title: 'Realizaste un pago recientemente',
      text: 'Para volver a realizar un pago de este proyecto debes esperar 10 minutos.',
      timeout: 600000,
      elementRef: 'IdElementRef',
      insertBefore: false
    });

  }


  tryAgainPaymentProject() {
    this.numeroIntentosSeleccionProyecto++;
    if (this.numeroIntentosSeleccionProyecto <= 2) {
      this.getMorgageBalance();
    } else {
      this.router.navigateByUrl('error-general')
    }
  }



  tryAgainAccountList() {
    this.numeroIntentosListarCuentas++;
    if (this.numeroIntentosListarCuentas <= 2) {
      this.getAllAccountList()
    } else {
      this.router.navigateByUrl('error-general')
    }
  }






}
