
export interface PaymentI {
  type: string,
  id: string,
  attributes: Attributes,
}

export class PaymentData{
  constructor(
    public type = 'PCSPAYMENTSRQ',
    public id  = '',
    public attributes = new Attributes(),
  ){}
}

export class Attributes{
  constructor(
    public companyId = '',
    public userId = '',
    public timestamp = new Date(),
    public ip = '',
    public appId = '',
    public appModule = '',
    public param = new Param()
  ){}
}
export class Param{
  constructor(
    public projectId = '',
    public sourceAccount = new Source(),
    public documentType =  '',
    public documentstring = '',
    public amount  = '',
    public totalPayment = '',
  ) {
  }
}
export class Source {
  constructor(
    public type =  '',
    public number = ''
  ){

  }
}
