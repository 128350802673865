import * as params from '../actions/shareparam.action';


const params1 = new params.Params('', '');


export function paramsreducer(state = params1, action: params.acciones): params.Params {
    switch (action.type) {
        case params.SET_PARAM:
            return {
                ...state,
                id: action.params.id,
                type: action.params.type
            };

        case params.SET_DATAAPP:
        return {
            ...state,
            dataapp: action.dataapp
        };
        default:
            return state;
    }
}
