import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

// Modulos Libreria SDB
import { BcAccordionModule } from '@bancolombia/design-system-web/bc-accordion';
import { BcButtonModule } from '@bancolombia/design-system-web/bc-button';
import { BcModalModule } from '@bancolombia/design-system-web/bc-modal';
import { BcIconModule } from '@bancolombia/design-system-web/bc-icon';
import { BcInputSelectModule } from '@bancolombia/design-system-web/bc-input-select';
import { BcBreadcrumbModule } from '@bancolombia/design-system-web/bc-breadcrumb';
import { BcStepperModule } from '@bancolombia/design-system-web/bc-stepper';
import { BcRadioModule } from '@bancolombia/design-system-web/bc-radio';
import { BcInputModule } from '@bancolombia/design-system-web/bc-input';
import { BcTooltipModule } from '@bancolombia/design-system-web/bc-tooltip';
@NgModule({
  declarations: [],
  imports: [
    BcRadioModule,
    BcStepperModule,
    BcBreadcrumbModule,
    BcInputSelectModule,
    BcAccordionModule,
    BcButtonModule,
    BcModalModule,
    BcInputModule,
    BcIconModule.forRoot({ path: 'https://library-sdb.apps.bancolombia.com/2.0.21' }),
    BcTooltipModule,
    CommonModule,
  ],
  exports: [
    BcRadioModule,
    BcStepperModule,
    BcBreadcrumbModule,
    BcInputSelectModule,
    BcAccordionModule,
    BcButtonModule,
    BcModalModule,
    BcIconModule,
    BcInputModule,
    BcTooltipModule,
    CommonModule,
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA ]
})
export class BcModule { }
