import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-something-wrong',
  templateUrl: './something-wrong.component.html',
  styleUrls: ['./something-wrong.component.css']
})
export class SomethingWrongComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
