export const environment = {
    production: false,
    // URL_AUTH: 'https://canales-digitales-ext-dev.apps.ambientesbc.com/integracion-sve/sesionmanagement/v2.1/authorization',
    // URL_CONSULTASERVICES: 'https://canales-digitales-ext-dev.apps.ambientesbc.com/portal-constructor/query/v1/relatedcompanies',
    // URL_CONSULTASALDO: 'https://canales-digitales-ext-dev.apps.ambientesbc.com/portal-constructor/query/v1/mortgageloanbalances',
    // URL_OBLIGACIONES: 'https://canales-digitales-ext-dev.apps.ambientesbc.com/portal-constructor/query/v1/obligations',
    // URL_MOVIMIENTOS: 'https://canales-digitales-ext-dev.apps.ambientesbc.com/portal-constructor/query/v1/movements',
    // URL_CLOSE_SESSION: 'https://canales-digitales-ext-dev.apps.ambientesbc.com/integracion-sve/sesionmanagement/v1/closesession',
    // URL_PAYMENT: 'https://canales-digitales-ext-dev.apps.ambientesbc.com/portal-constructor/payments/v1/creditpayments',
    // URL_INTE: 'https://canales-digitales-ext-dev.apps.ambientesbc.com/integracion-sve/sesionmanagement',
    // URL_BASE: 'https://canales-digitales-ext-dev.apps.ambientesbc.com/portal-constructor/api/v1',
    ENVIROMENT: '#{environment}#',
    YEAR: parseInt('#{year}#'),
    MONTH: parseInt('#{month}#'),
    DAY: parseInt('#{day}#'),
    PATH_FFLAG: '#{PATH_FFLAG}#',
    API_KEY_FFLAG: '#{API_KEY_FFLAG}#',
    URL_INTE: '#{URL_INTE}#',
    URL_BASE: '#{URL_BASE}#'

};



