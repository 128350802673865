import { Component, OnInit, ViewChild, ChangeDetectorRef} from '@angular/core';
import { FormBuilder, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { BcCardSelectComponent } from '@bancolombia/design-system-web/bc-card-select';
import { PaymentsService } from '../../services/payments.service';
import { BcCardProductConfig, BcCardType, BcFooterButtonConfig } from "@bancolombia/design-system-web/bc-behaviors";
import { AccountnumberPipe } from "../../../shared/pipes/accountNumber/accountnumber.pipe";
import { parsedValueToCop } from "../../../shared/utilities/ParserToCop";
import { ObligationsService } from '../../../query/services/obligations.service';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
@Component({
  selector: 'payment-operation-details',
  templateUrl: './payment-operation-details.component.html',
  styleUrls: ['./payment-operation-details.component.css']
})
export class PaymentOperationDetailsComponent implements OnInit{

  stepsArray: string[] = ['Datos del pago', 'Verificación'];
  @ViewChild('cardSelectCuentas') cardSelectCuentas: BcCardSelectComponent;
  @ViewChild('cardSelect') cardSelectObligaciones: BcCardSelectComponent;
  paymenFrm: UntypedFormGroup;
  loader:boolean = false;

  // Variables para listar cuentas
  accounts=[];
  resultAccountList = [];
  ocurrioUnErrorListarCuentas: boolean = false;
  paginaListarCuentas = 1;
  numeroRegistrosListarPaginas = 30;
  resultMoreAccounts;
  accountList: Array<BcCardProductConfig> = [];
  defaultValueAccount: any;
  showOtherAmount = false;
  deshabilitarBotonSaldoInsuficiente = false;
  statusOtherAmount = 'default';
  showErrorOtherAmount = false;
  codigoError: string = '';
  mostrarErrorCuentasNoDisponibles: boolean = false;
  numeroIntentosListarCuentas: number = 0;
  // fin de variables para listar cuentas

  // Variables para listar obligaciones
  projectObligations: any[] = [];
  cardObligations: any[] = [];
  idObligationSelected: any;
  obligationSelected: any;

  //Fin de variables para listar obligaciones

  constructor(public paymentService: PaymentsService,
              public fb: FormBuilder,
              public router:Router,
              private changeDetectorRef: ChangeDetectorRef,
              public obligationService: ObligationsService
  ) {
    this.idObligationSelected = "";
   }


  ngOnInit(): void {
    this.validateForm();
    this.getAllAccountList();
    this.getObligaciones();
    this.changeValueOtherAmount();
  }

  validateForm(){
    this.paymentFrm();
    if(this.paymentService.getObligationPayment){
      this.paymenFrm.patchValue(this.paymentService.getObligationPayment.value)
    }
  }

  paymentFrm() {
    this.paymenFrm = this.fb.group({
      obligation: ['', [Validators.required]],
      amount:['1', [Validators.required]],
      otherAmount: [''],
      account: ['', [Validators.required]],
      typeAccount: [''],
      titleAccount: [''],
      totalPayment: [''],
      projectName: [''],
      projectNumber: [''],
      typeSelected: ['']
    })
  }

  //Metodos para listar las obligaciones

  getObligaciones(){
    this.projectObligations = this.obligationService.getObligaciones;
    this.obligationSelected = this.obligationService.getObligacion;
    this.createDataObligations(this.projectObligations);
    this.paymenFrm.patchValue({
      totalPayment: this.obligationSelected.obligacion.copTotalBalance,
      projectName: this.obligationSelected.projectName,
      projectNumber: this.obligationSelected.projectNumber
    }, {
      emitEvent: false
    });
  }

  createDataObligations(response: any) {

    setTimeout(() => {
      this.cardObligations = response.map((obligation:any) => {
        return {
          tittle: `Crédito ${obligation.obligationId}`,
          subtittle: [`Tasa de desembolso ${obligation.negotiatedDisbursementRate}`],
          cardType: BcCardType['none-icon'],
          titleBalance: 'Saldo total',
          statusType: 'custom-warning',
          balance: `COP ${parsedValueToCop(obligation.copTotalBalance + '')}`,
          disabled: true,
          aqdivider: true,
          value: obligation.obligationId
        } as any;
      })
      this.idObligationSelected = this.obligationService.getObligacion.obligacion.obligationId + '';
    },0)

  }

  // fin del metodo para listar obligaciones

  // Metodos para listar las cuentas
  getAllAccountList(verMas?: string) {
    if(verMas === undefined){
      this.getAccounst();
    } else {
      this.paginaListarCuentas ++;
      this.getAccounst();
    }

  }
  getAccounst() {
    this.loader = true;
    if(this.resultAccountList.length > 0){
      this.accounts = this.resultAccountList;
    }
    this.paymentService.getAccountList(this.paginaListarCuentas, this.numeroRegistrosListarPaginas).subscribe(response => {
      this.ocurrioUnErrorListarCuentas = false;
      this.resultAccountList = response.data.attributes.result.accountList;
      this.resultMoreAccounts = response.data.attributes.result.flagMoreRecords;
      if (!this.resultMoreAccounts) {
        if(this.accounts.length > 0){
          this.resultAccountList = this.resultAccountList.concat(this.accounts);
        }
      }
      this.createDataAccountList(this.resultAccountList);
      this.loader = false;
    }, (error) => {

      if (error.status === 404) {
        this.codigoError = error.error.errors[0].code;
        if (this.codigoError === 'CTB003') {
          this.mostrarErrorCuentasNoDisponibles = true;
        } else {
          this.ocurrioUnErrorListarCuentas = true;
        }
      } else {
        this.codigoError = error.error.errors[0].code;
        this.ocurrioUnErrorListarCuentas = true;
      }
      this.loader = false;
    })
  }

  tryAgainAccountList() {
    this.numeroIntentosListarCuentas++;
    if (this.numeroIntentosListarCuentas <= 2) {
      this.getAllAccountList()
    } else {
      this.router.navigateByUrl('error-general')
    }
  }

  createDataAccountList(response: any) {
    setTimeout(() => {
      this.accountList = response.map((account: any) => {
        return {
          tittle: account.customName,
          subtittle: [this.getSubtype(account.type), new AccountnumberPipe().transform(account.accountNumber)],
          value: new AccountnumberPipe().transform(account.accountNumber),
          cardType: BcCardType['none-icon'],
          balance: `COP ${parsedValueToCop(account.balance + '')}`,
          titleBalance: 'Saldo disponible',
          statusType: this.isAccountSufficient(account) ? null : 'custom-warning',
          additionalText: this.isAccountSufficient(account) ? null : 'Esta cuenta no tiene fondos suficientes para realizar este pago',
          disabled: true,
          aqdivider: true,
        } as any;
      });
      if(this.paymenFrm.get('account').value){
        this.defaultValueAccount = this.paymenFrm.get('account').value;
      }
    },0)




}

  getSubtype(account: any) {
    switch (account) {
      case 'CUENTA_CORRIENTE':
        return 'Cuenta corriente';
      case 'CUENTA_DE_AHORRO':
        return 'Cuenta de ahorros';
      default:
        break;
    }
  }

  isAccountSufficient(account: any): boolean {
    const accountBalance = parseFloat(account.balance || '0');
    this.amountSelected()
    return accountBalance >= parseFloat(this.paymenFrm.value.amount || '0');
  }

  changeSelectAccountList(account: any) {
    this.deshabilitarBotonSaldoInsuficiente = false;
    this.paymenFrm.patchValue({
      typeAccount: `${account.subtittle[0]}`,
      titleAccount: `${account.tittle}`
    }, {
      emitEvent: false
    });

    if (account.value === this.paymenFrm.value.account) {
      if (account.additionalText === 'Esta cuenta no tiene fondos suficientes para realizar este pago') {
        this.deshabilitarBotonSaldoInsuficiente = true;
      }
      return;
    }else{
      this.paymenFrm.patchValue({
        typeAccount: `${account.subtittle[0]}`,
        titleAccount: `${account.tittle}`,
        account: `${account.value}`
      }, {
        emitEvent: false
      });

      if (account.additionalText === 'Esta cuenta no tiene fondos suficientes para realizar este pago') {
        this.deshabilitarBotonSaldoInsuficiente = true;
      }
    }



  }

  private getDropdownDefaultIndex(defaultValue: string) {

    return (this.cardSelectCuentas?.values as { subtittle: string[] }[])?.findIndex((item: any) => item.subtittle[1] === defaultValue);
  }

  private refreshDropdownComponent(): void {
    const defaultValue = this.paymenFrm.get('account').value!;
    if (defaultValue !== "") {
      this.cardSelectCuentas?.updateDropdownValues(this.accountList);
      const defaultIndex = this.getDropdownDefaultIndex(defaultValue);
      this.cardSelectCuentas?.bcCardSelectBehavior?.markItemByIndex(defaultIndex);
      const lis = document.querySelectorAll('#selector-account-origin li');
      lis[defaultIndex].dispatchEvent(new Event('click'));
      this.changeSelectAccountList(this.cardSelectCuentas.values[defaultIndex]);
    }

  }

  public updateListAccount(): void {
    this.changeDetectorRef.markForCheck();
    this.accountList = [];
    this.createDataAccountList(this.resultAccountList);
    setTimeout(() => {
      this.refreshDropdownComponent();
    }, 0);

  }

  // Fin de metodos para listar cuentas

  amountSelected() {
    switch (this.paymenFrm.value.amount) {
      case '1':
        this.paymenFrm.value.typeSelected = '1';
        this.paymenFrm.value.amount = this.obligationSelected.obligacion.copTotalBalance;

        break;
      case '2':
        this.paymenFrm.value.typeSelected = '2';
        const otherAmountConvert = parseFloat(this.paymenFrm.value.otherAmount.replace(/\./g, ''));
        // this.typeOfPayment = 'U';
        this.paymenFrm.value.amount = otherAmountConvert;
        break;
    }
  }

  showSelected(value: any) {
    switch (value) {
      case '1':
        if(this.paymenFrm.get('account').value !== ""){
          this.updateListAccount();
        }
        if(this.paymenFrm.get('otherAmount').value !== ""){
          this.paymenFrm.get('otherAmount').setValue('');
        }
        this.showOtherAmount = false;
        // this.showAlert = true;
        break;
      case '2':
        // this.showAlert = false;
        this.showOtherAmount = true;
        break;
    }
  }
  changeValueOtherAmount() {
    this.paymenFrm.get('otherAmount')?.valueChanges.subscribe((value) => {
      const convertOtherAmount = parseFloat(value.replace(/\./g, ''));
      const convertTotalPayment = parseFloat(this.paymenFrm.value.totalPayment);
      this.statusOtherAmount = (convertOtherAmount > convertTotalPayment) ? 'error' : 'default';
      this.showErrorOtherAmount = this.statusOtherAmount === 'error' ? true : false;
      this.updateListAccount();
    })
  }

  goToVerifyPaymentObligation(){
    this.amountSelected();
    this.paymentService.saveInfoObligationPayment(this.paymenFrm);
    this.router.navigateByUrl('pagos/verifyPaymentOperation')
  }


}
