export class MortgageBalance {
    accountDetails: any[];
    cardNumber: number;
    idCard: any;
    typeDocCard: any;
    lastID: any

    constructor(accountDetails, cardNumber, id, type, lastId) {
        this.accountDetails = accountDetails;
        this.cardNumber = cardNumber;
        this.idCard = id;
        this.typeDocCard = type;
        this.lastID = lastId;
    }
}