import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { QueryBalanceComponent } from './components/query-balance/query-balance.component';
import { QueryDetailComponent } from './components/query-detail/query-detail.component';

const routes: Routes = [
  {
    path: '',
    component: QueryBalanceComponent
  },
  {
    path: '',
    component: QueryDetailComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class QueryRoutingModule { }
