import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PaymentDetailsComponent } from './payment-details/payment-details.component';
import { ErrorGeneralComponent } from './errorGeneral/error-general/error-general.component';
import { PaymentOperationDetailsComponent } from './payment-operation/payment-operation-details/payment-operation-details.component';
import { VerifyObligationPaymentComponent } from './payment-operation/verifyPayment/verify-obligation-payment/verify-obligation-payment.component';
import { ConfirmPaymentObligationComponent } from './payment-operation/confirmPayment/confirm-payment-obligation/confirm-payment-obligation.component';


const routes: Routes = [
  { path: '', component: PaymentDetailsComponent },
  { path: 'operaciones', component: PaymentOperationDetailsComponent },
  { path: 'verifyPaymentOperation', component: VerifyObligationPaymentComponent},
  { path: 'confirmPaymentObligation', component: ConfirmPaymentObligationComponent},
  { path: 'error-general', component: ErrorGeneralComponent}
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PaymentsRoutingModule { }
