import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { DatePipe } from '@angular/common';
import { CommonModule } from "@angular/common";
import { TableComponent } from "./table/table.component";
import { NotifyComponent } from "./notify/notify.component";
import { QueryDetailComponent } from "../query/components/query-detail/query-detail.component";
import { TruncateValuePipe } from "../shared/pipes/truncate/truncate-value.pipe";
import { DetailproyectComponent } from "../generic-components/detailproyect/detailproyect.component";
import { FormatDatePipe } from "../shared/pipes/format/format-date.pipe";
import { DirectivesModule } from "../shared/directives/directives.module";
import { MovementsComponent } from "../generic-components/movements/movements.component";
import { AppMaterialModule } from "../shared/app-material.module";
import { PopupInfoComponent } from "./popup-info/popup-info.component";
import { GenericRoutingModule } from './generic-routing.module';
import { BcModule } from "../shared/bc/bc.module";
import { RouterModule } from "@angular/router";
import { PipesModule } from '../shared/pipes/pipes.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SomethingWrongComponent } from './something-wrong/something-wrong.component';
import { LoaderBlurModule } from "./loader-blur/module/loader-blur.module";
import { LoadingComponent } from './loading/loading.component';


import { BcDialogService } from "@bancolombia/design-system-web/bc-services";
import { BcTableModule } from '@bancolombia/design-system-web/bc-table';
import {BcPaginatorModule} from '@bancolombia/design-system-web/bc-paginator';
import {BcTabsModule} from '@bancolombia/design-system-web/bc-tabs-group'
import {BcModalModule} from '@bancolombia/design-system-web/bc-modal';
import { BcPreloaderModule } from '@bancolombia/design-system-web/bc-preloader';
import { BcIconModule } from '@bancolombia/design-system-web/bc-icon';
import { BcIllustrationModule } from '@bancolombia/design-system-web/bc-illustration';
import { BcPictogramModule } from '@bancolombia/design-system-web/bc-pictogram';
import { BcInlineAlertModule } from '@bancolombia/design-system-web/bc-inline-alert';



@NgModule({
    imports: [
        CommonModule,
        DirectivesModule,
        RouterModule,
        AppMaterialModule,
        GenericRoutingModule,
        BcModule,
        PipesModule,
        ReactiveFormsModule,
        LoaderBlurModule,
        FormsModule,
        BcTableModule,
        BcPaginatorModule,
        BcTabsModule,
        BcModalModule,
        BcPreloaderModule,
        BcInlineAlertModule,
        BcIconModule.forRoot({
          path:'https://library-sdb.apps.bancolombia.com/bds/6.31.1'
        }),
        BcIllustrationModule.forRoot({ path: 'https://library-sdb.apps.bancolombia.com/bds/6.31.1' }),
        BcPictogramModule.forRoot({ path: 'https://library-sdb.apps.bancolombia.com/bds/6.31.1'}),
    ],
    declarations: [
        TableComponent,
        NotifyComponent,
        QueryDetailComponent,
        TruncateValuePipe,
        FormatDatePipe,
        DetailproyectComponent,
        MovementsComponent,
        PopupInfoComponent,
        SomethingWrongComponent,
        LoadingComponent
    ],
    providers: [
        BcDialogService,
        DatePipe
    ],
    exports: [TableComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class GenericModule {}
