import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.reducers';
import { Observable } from 'rxjs';
import { formatDate } from '@angular/common';
import { FormGroup } from '@angular/forms';

const urlBase = environment.URL_BASE;

const TRANSACTION_CODE_ACCOUNTLIST = '2105';


@Injectable({
  providedIn: 'root'
})
export class PaymentsService {
  httpHeaders = new HttpHeaders().append(
    'Content-Type', 'application/json; charset=UTF8'
  );
  data: any;
  dataProjects: any;
  pendingInfService: any;
  pendingMoreAccounts: any;
  projectId: string;
  myDate = new Date();

  obligationPayment: FormGroup;

  paidObligationInformation: any;

  infoPaidObligationPayment: any;

  constructor(private store: Store<AppState>, private http: HttpClient) {
  }

  getDataStore() {
    this.store.select('shareparams').subscribe(r => {
      this.data = r.dataapp;
    });
  }

  saveData(id: string, data: any[]) { // id =  Data = listaProyectos
    this.dataProjects = data;
    this.projectId = id;
  }

  getDataProjects(): any[] {
    return this.dataProjects;
  }

  get getIdProject(): string {
    return this.projectId;
  }

  saveInfoObligationPayment(infoObligation: FormGroup){
    // Formulario: Llenar la información de pago por obligacion
    this.obligationPayment = infoObligation;
    // paidObligationInformation = Carga la data en el componente confirm-payment-obligation
    this.paidObligationInformation = infoObligation;
  }



  get getObligationPayment(){
    return this.obligationPayment
  }

  get getPaidObligationPayment(){
    return this.paidObligationInformation
  }

  savedWhenPaidObligation(infoWhenPaid){
    this.infoPaidObligationPayment = infoWhenPaid;
  }

  get getWhenPaidObligation(){
    return this.infoPaidObligationPayment;
  }

  saveRes(pendingInfo: any) {
    this.pendingInfService = pendingInfo;
  }

  get getPendingInfoMoreProjects(): string {
    return this.pendingInfService;
  }



  validateSecondPayment(projectId): Observable<any> {

    this.getDataStore();
    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'authorization': 'Bearer ' + this.data.jwt,
      'message-id': this.data.id
    });
    const rq = {
      "data": {
        "type": "PCSVALIDATEPAYMENTSRQ",
        "id": this.data.id,
        "attributes": {
          "companyName": this.data.companyname,
          "docType": this.data.docType,
          "companyId": this.data.companyId,
          "userId": "aleja4",
          "timestamp": formatDate(this.myDate, 'yyyy-MM-dd HH:mm:ss:SS', 'en-US', 'UTC -05:00'),
          "ip": this.data.ip,
          "appId": "PCS",
          "appModule": "002-2",
          "param": {
            "obligation": {
              "projectId": `${projectId}`
            },
            "customerInformation": {
              "documentType": this.data.docType,
              "documentNumber": this.data.docId
            }
          }
        }
      }
  };

    return this.http.post(`${urlBase}/ch-ms-payments-constructor/validate-payments`, rq, { headers: this.httpHeaders });
  }

  getAccountList(numeroPagina, cantidadRegistrosPagina):Observable<any>{

    this.getDataStore();
    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'authorization': 'Bearer ' + this.data.jwt,
      'transaction-code':'2105',
      'message-id': this.data.id
    });
    const rq = {
        "data": {
          "type": "ACCOUNTSLISTRQ",
          "id": this.data.id,
          "attributes": {
            "companyName": this.data.companyname,
            "docType": this.data.docType,
            "companyId": this.data.companyId,
            "userId": "aleja4",
            "timestamp": formatDate(this.myDate, 'yyyy-MM-dd HH:mm:ss:SS', 'en-US', 'UTC -05:00'),
            "ip": this.data.ip,
            "appId": "PCS",
            "appModule": "002-2",
            "param": {
              "customerInformation": {
                "typeDocument": this.data.docType,
                "idDocument": this.data.docId
              },
              "filter": {
                "allowDebit": true,
                "allowCredit": true,
                "status": "ACTIVO",
                "relation": "TITULAR",
                "specifications": [

                ]
              },
              "pagination": {
                "pageNumber": `${numeroPagina}`,
                "recordsPage": `${cantidadRegistrosPagina}`
              }
            }
          }
        }
    };

    return this.http.post(`${urlBase}/ch-ms-deposits/retrieve-account-list`, rq, { headers: this.httpHeaders })
    // return this.http.post(`${urlBase}/payments/v1/accountList`, rq)

  }

  paymentCredit(paymentInformation:any): Observable<any>{

    this.getDataStore();
    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'authorization': 'Bearer ' + this.data.jwt,
      'message-id': this.data.id
    });
    const rq = {
      "data": {
        "type": "PCSAPPLYPAYMENTRQ",
        "id": this.data.id,
        "attributes": {
          "companyName": this.data.companyname,
          "docType": this.data.docType,
          "companyId": this.data.companyId,
          "userId": "aleja4",
          "timestamp": formatDate(this.myDate, 'yyyy-MM-dd HH:mm:ss:SS', 'en-US', 'UTC -05:00'),
          "ip": this.data.ip,
          "appId": "PCS",
          "appModule": "002-2",
          "param": {
            "obligation": {
              "projectId": `${paymentInformation.projectId}`
            },
            "customerInformation": {
              "documentType": this.data.docType,
              "documentNumber": this.data.docId
            },
            "accountInformation": {
              "type": `${paymentInformation.type}`,
              "number":  `${paymentInformation.account}`
            },
            "paymentInformation": {
              "amount":  `${paymentInformation.amount}`,
              "currency": "COP",
             "typeOfPayment":  `${paymentInformation.typeOfPayment}`
            }
          }
        }
      }
    };
  return this.http.post(`${urlBase}/ch-ms-payments-constructor/apply-payment`, rq, { headers: this.httpHeaders })

  }

  paymentObligation(paymentObligationInformation: any): Observable<any> {
    this.getDataStore();
    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'authorization': 'Bearer ' + this.data.jwt,
      'message-id': this.data.id
    });
    const rq = {
      "data": {
        "type": "PCSAPPLYPAYMENTRQ",
        "id": this.data.id,
        "attributes": {
          "companyName": this.data.companyname,
          "docType": this.data.docType,
          "companyId": this.data.companyId,
          "userId": "aleja4",
          "timestamp": formatDate(this.myDate, 'yyyy-MM-dd HH:mm:ss:SS', 'en-US', 'UTC -05:00'),
          "ip": this.data.ip,
          "appId": "PCS",
          "appModule": "002-2",
          "param": {
            "paymentInformation": {
              "typePay": `${paymentObligationInformation.typePay}`,
              "amountTransfer": `${paymentObligationInformation.amountTransfer}`
            },
            "obligation": {
              "projectId": `${paymentObligationInformation.projectId}`,
              "obligationId": `${paymentObligationInformation.obligationId}`
            },
            "accountInformation": {
              "typeAccount": `${paymentObligationInformation.typeAccount}`,
              "number": `${paymentObligationInformation.number}`
            },
            "customerInformation": {
              "documentType": this.data.docType,
              "documentNumber": this.data.docId
            },
          }
        }
      }
    };

    return this.http.post(`${urlBase}/ch-ms-payments-constructor/apply-payment-by-obligation`, rq, { headers: this.httpHeaders })

  }




}
