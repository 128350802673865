import { Action } from '@ngrx/store';
export const SET_PARAM = '[PARAM] set iddetail';
export const SET_DATAAPP = '[PARAM] set setdataApp';
export const SET_RFHTOKEN = '[PARAM] set rfhtoken';

export class Params {
    public id: string;
    public type: string;
    public dataapp: JSON;
    public rfhtoken: string;

    constructor(id: string, type: string) {
        this.id = id;
        this.type = type;
        this.dataapp = JSON;
        this.rfhtoken = '';
    }
}


export class SetIdDetailAction implements Action {
    readonly type = SET_PARAM;

    constructor(public params: Params) {

    }
}

export class SetDataApp implements Action {
    readonly type = SET_DATAAPP;

    constructor(public dataapp: JSON) {

    }
}

export type acciones = SetIdDetailAction| SetDataApp;
