export const dataItems = [
  {
      "name": "Fecha",
      "respName": "dateValue",
      "check": true
  },
  {
      "name": "Origen",
      "respName": "origin",
      "check": true
  },
  {
      "name": "Descripción",
      "respName": "description",
      "check": true
  },
  {
      "name": "Número de crédito",
      "respName": "obligationId",
      "check": true
  },
  {
      "name": "Valor total",
      "respName": "totalValue",
      "check": true
  },
  {
      "name": "Identificación del cliente",
      "respName": "idNumber",
      "check": true
  },
  {
      "name": "Nombre del cliente",
      "respName": "disbursementCustomerName",
      "check": true
  },
  {
      "name": "Valor capital",
      "respName": "capitalValue",
      "check": false
  },
  {
      "name": "Valor intereses",
      "respName": "interestValue",
      "check": false
  },
  {
      "name": "Valor intereses de mora",
      "respName": "arrearInterestValue",
      "check": false
  },
  {
      "name": "Número de cuota",
      "respName": "paymentNumber",
      "check": false
  },
];

// export var itemsData = [
//     {
//         "name": "Identificación del cliente",
//         "respName": "idNumber",
//         "orden": 1,
//         "check": true
//     },
//     {
//         "name": "Nombre del cliente",
//         "respName": "disbursementCustomerName",
//         "orden": 2,
//         "check": true
//     },
//     {
//         "name": "Número de crédito",
//         "respName": "obligationId",
//         "orden": 3,
//         "check": true
//     },
//     {
//         "name": "Descripción",
//         "respName": "description",
//         "orden": 4,
//         "check": true
//     },
//     {
//         "name": "Origen",
//         "respName": "origin",
//         "orden": 5,
//         "check": true
//     },
//     {
//         "name": "Fecha",
//         "respName": "dateValue",
//         "orden": 6,
//         "check": true
//     },
//     {
//         "name": "Valor capital",
//         "respName": "capitalValue",
//         "orden": 7,
//         "check": false
//     },
//     {
//         "name": "Valor intereses",
//         "respName": "interestValue",
//         "orden": 8,
//         "check": false
//     },
//     {
//         "name": "Valor intereses de mora",
//         "respName": "arrearInterestValue",
//         "orden": 9,
//         "check": false
//     },
//     {
//         "name": "Valor total",
//         "respName": "totalValue",
//         "orden": 10,
//         "check": true
//     },
//     {
//         "name": "Número de cuota",
//         "respName": "paymentNumber",
//         "orden": 11,
//         "check": false
//     },
// ]
