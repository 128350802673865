import { Action } from '@ngrx/store';
import {CompaniesBalance} from '../../shared/models/campanies.model';

export enum QueryActionTypes {
    GET_ITEMS = '[GET] Obtain all items',
    EMPTY_ITEMS = '[GET] Failed to obtain items',
    LIST_ITEMS_SUCCESS = '[GET] Success to load all items from server'
}
export class GetItemsQuery implements Action {
    readonly type = QueryActionTypes.GET_ITEMS;
        constructor(public _item: Array<CompaniesBalance>, public _data: JSON) {
        }
}
export class GetItemsQueryFailed implements Action {
    readonly type = QueryActionTypes.EMPTY_ITEMS;
        constructor(public _error: any) {
    }
}
export class GetItemsQuerySuccess implements Action {
    readonly type = QueryActionTypes.LIST_ITEMS_SUCCESS;
        constructor(public _item: Array<CompaniesBalance>) {
    }
}

export type All =
 GetItemsQuery
 | GetItemsQueryFailed
 | GetItemsQuerySuccess;
