import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { formatDate } from '@angular/common';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.reducers';
const urlBase = environment.URL_BASE;
@Injectable({
  providedIn: 'root'
})
export class ObligationsService {

  httpHeaders: HttpHeaders;
  myDate = new Date();
  data: any;

  obligacionSeleccionada: any;
  obligacionesSeleccionadas: any[] = [];

  constructor(private store: Store<AppState>, private http: HttpClient) { }


  guardarObligacion(obligacion: any, projectName: string, projectNumber: string){
    const objObligacion = {
      obligacion,
      projectName,
      projectNumber
    }
    this.obligacionSeleccionada = objObligacion;
  }

  get getObligacion(): any {
    return this.obligacionSeleccionada;
  }

  saveObligaciones(obligaciones: any) {
    this.obligacionesSeleccionadas = obligaciones;
  }

  get getObligaciones(): any {
    return this.obligacionesSeleccionadas
  }

  getJwt(): void {
    this.store.select('shareparams').subscribe(r => {
      this.data = r;
      this.httpHeaders = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.data.dataapp.jwt
      });
    });
  }

  pcsQueryObligationsRq(data: any, id: any, page: any, records: any, documentNumber:any, documentType:any): Observable<any> {
    this.getJwt();
    const requestData = {
      'data': {
        'id': data.id,
        'type': 'PCSQUERYOBLIGATIONSRQ',
        'attributes': {
          'companyId': data.companyId,
          'userId': data.userId,
          'timestamp': formatDate(this.myDate, 'yyyyMMddHHmmssSS', 'en-US', 'UTC -05:00'),
          'ip': data.ip,
          'appId': data.appId,
          'appModule': data.appModule,
          'param': {
            "recordsPerPage": records,
            "pageNumbers": page,
            "projectId": id,
            "documentNumber": documentNumber,
            "documentType": documentType
          }
        }

      }

    };
    return this.http.post(`${urlBase}/ch-ms-queries-constructor/obligations`, JSON.stringify(requestData), { headers: this.httpHeaders });
  }
}
