import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { QueryActionTypes, GetItemsQuery, GetItemsQueryFailed, GetItemsQuerySuccess } from '../actions/query.actions';
import { map, tap, switchMap, catchError } from 'rxjs/operators';
import { NitserviceService } from '../../query/services/nitservice.service';


@Injectable()
export class QueryEffects {
    constructor(private actions: Actions, private service: NitserviceService) {
    }
    @Effect()
    Get$: Observable<any> = this.actions.pipe(ofType(QueryActionTypes.GET_ITEMS),
            map((action: GetItemsQuery) => action),
            switchMap(t => {
                return this.service.pcsQueryRelatedCompaniesRq(t._data).pipe(
                    map( k => {
                        if (k.data.attributes.param.relatedCompanies) {
                            k.data.attributes.param.relatedCompanies.forEach(element => {
                            element.Show = false;
                            element.Empty = false;
                            element.pager = false;
                        }); }
                        return new GetItemsQuerySuccess(k.data.attributes.param.relatedCompanies);
                    }),
                    catchError(error => {
                        return of(new GetItemsQueryFailed(error));
                    })
                );
            })
        );

    @Effect({ dispatch: false })
    GetSuccess$: Observable<any> = this.actions.pipe(
        ofType(QueryActionTypes.LIST_ITEMS_SUCCESS),
        tap((list) => {
            return new GetItemsQuerySuccess(list._item);
        })
    );
    @Effect({ dispatch: false })
    GetFailed$: Observable<any> = this.actions.pipe(
        ofType(QueryActionTypes.EMPTY_ITEMS),
        tap((error) => {
            return error;
        })
    );
}
