import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FinishOption003Directive } from './finish-option-003.directive';
import { FinishOption004Directive } from './finish-option-004.directive';
import { RefreshDirective } from './refresh.directive';
import { RefreshhoverDirective } from './refresh-hover.directive';
import { FinishOptionComponent } from './finish-option.component';
import { RefreshHoverComponent } from './refresh-hover.component';
import { RefreshComponent } from './refresh.component';


@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    FinishOption003Directive,
    FinishOption004Directive,
    RefreshDirective,
    RefreshhoverDirective,
    FinishOptionComponent,
    RefreshHoverComponent,
    RefreshComponent
  ],
  exports: [
    FinishOption003Directive,
    FinishOption004Directive,
    RefreshDirective,
    RefreshhoverDirective
  ]
})
export class DirectivesModule { }
