import { Component, ElementRef, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { QueryService } from './query/services/query.service';
import { Store } from '@ngrx/store';
import { SetDataApp } from './store/actions/shareparam.action';
import { environment } from '../environments/environment';
import { AppState } from './app.reducers';
const urlBase = environment.URL_INTE;

@Component({
  selector: 'app-root',
  template: '<div id="content"><router-outlet></router-outlet></div>',
  providers: [QueryService, Store]
})
export class AppComponent implements OnInit {

  param: any;

  constructor(
    private router: Router,
    public store: Store<AppState>,
    public queryService: QueryService,
    public activatedRoute: ActivatedRoute,
    private _elementRef: ElementRef) {
    sessionStorage['params'] = '';
    this.getmessageurl();

  }

  ngOnInit() {
    this._elementRef.nativeElement.removeAttribute("ng-version");
    const assertion: boolean = this.validateFormat(this.param.assertion);
    const enctoken: boolean = this.validateFormat(this.param.enctoken);
    const signature: boolean = this.validateFormat(this.param.signature);
    const sender: boolean = this.validateFormat(this.param.sender);
    if (sender && enctoken && signature && assertion) {
      this.requestServiceAuth();
    } else {
      const msgerror: any = 'No es posible continuar con la transacción en este momento.';
      const actionerror = new SetDataApp(msgerror);
      this.store.dispatch(actionerror);
      this.router.navigate(['notificacion']);
    }
  }
  public requestServiceAuth() {
    this.queryService.sendData(`${urlBase}/v2.1/authorization`, this.param).subscribe(response => {
      const companyDocid: boolean = this.validateFormat(response.data.attributes.param.appVars.company.docId);
      const companyDoctype: boolean = this.validateFormat(response.data.attributes.param.appVars.company.docType);
      const companyName: boolean = this.validateFormat(response.data.attributes.param.appVars.company.name);
      if (companyDocid && companyDoctype && companyName) {
        this.buildDataRequest(response);
      } else {
        const msgerror: any = 'No es posible continuar con la transacción en este momento.';
        const actionerror = new SetDataApp(msgerror);
        this.store.dispatch(actionerror);
        this.router.navigate(['notificacion']);
      }
    }, fail => {
      const error: any = (fail.error.errors?.length > 0) ? fail.error.errors[0].detail :
        'No es posible continuar con la transacción en este momento.';
      const actionerror = new SetDataApp(error);
      this.store.dispatch(actionerror);
      this.router.navigate(['notificacion']);
    });
  }
  public buildDataRequest(respAuth) {
    const data: any = {
      'id':respAuth.data.id,
      'companyId': respAuth.data.attributes.companyId,
      'userId': respAuth.data.attributes.userId,
      'ip': respAuth.data.attributes.ip,
      'appId': respAuth.data.attributes.appId,
      'appModule': respAuth.data.attributes.appModule,
      'docId': respAuth.data.attributes.param.appVars.company.docId,
      'docType': respAuth.data.attributes.param.appVars.company.docType,
      'companyname': respAuth.data.attributes.param.appVars.company.name,
      'jwt': respAuth.data.attributes.param.jwt,
      'rthtoken': respAuth.data.attributes.param.refreshToken
    };
    const acciondata = new SetDataApp(data);
    this.store.dispatch(acciondata);
    this.router.navigate(['consulta']);
  }

  public validateFormat(parametro: any): boolean {
    if (parametro !== null && parametro !== '' && parametro !== undefined) {
      return true;
    }
    return false;
  }

  public findGetParameter(parameterName) {
    let result = '',
      tmp = [];
    location.search
      .substr(1)
      .split('&')
      .forEach(function (item) {
        tmp = item.split('=');
        if (tmp[0] === parameterName) { result = decodeURIComponent(tmp[1]); }
      });
    return result;
  }


  public getmessageurl() {
    this.param = atob(this.findGetParameter('message'));
    if (this.param) {
      sessionStorage.setItem('params', this.param);
      sessionStorage.setItem('urlClose',`${urlBase}/v1/closesession`);
      this.param = JSON.parse(this.param);
    } else {
      this.router.navigate(['notificacion']);
    }
  }
}



