import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateValue'
})
export class TruncateValuePipe implements PipeTransform {

  transform(value: string, maxLength: number, limitLength: number): any {
    if (value !== null && value.length > maxLength) {
      return value.substring(0, limitLength) + '...';
    } else if (value == null) {
      value = '--';
      return value;
    } else {
      return value;
    }
  }

}
