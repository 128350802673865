import { Directive, HostListener } from '@angular/core';


 function sendMessage(any){};
@Directive({ selector: '[refreshover]' })

export class RefreshhoverDirective {
  @HostListener('mousemove', ['$event'])
  refreshSession(event: Event) {
    sendMessage({'action': 'refreshSession'});
   }
}
