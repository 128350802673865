import { Directive, HostListener } from '@angular/core';

// declare const Porthole: any;
@Directive({
  selector: '[FinishOption004]'
})
export class FinishOption004Directive {
  @HostListener('click', ['$event'])
  finishOption(event: Event) {
    // const windowProxy = new Porthole.WindowProxy('todo1');
    // windowProxy.post({
    //   'action': 'opt004Finished'
    // });

  }

}
