import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { formatDate } from '@angular/common';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.reducers';
import { DataMovementsSce } from '../../shared/models/dataQueryMovements';

const urlBase = environment.URL_BASE;
@Injectable({
  providedIn: 'root'
})
export class MovementsService {

  httpHeaders: HttpHeaders;
  myDate = new Date();
  data: any;

  constructor(private store: Store<AppState>, private http: HttpClient) { }

  getJwt(): void {
    this.store.select('shareparams').subscribe(r => {
      this.data = r;
      this.httpHeaders = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.data.dataapp.jwt
      });
    });
  }

  pcsQueryMovementsRq(data: any, queryMovements: DataMovementsSce): Observable<any> {
    this.getJwt();
    const requestData = {
      'data': {
        'type': 'PCSQUERYMOVEMENTSRQ',
        'id': data.id,
        'attributes': {
          'companyId': data.companyId,
          'userId': data.userId,
          'timestamp': formatDate(this.myDate, 'yyyyMMddHHmmssSS', 'en-US', 'UTC -05:00'),
          'ip': data.ip,
          'appId': data.appId,
          'appModule': data.appModule,
          'param': {
            "recordsPerPage": queryMovements.recordsNum,
            "pageNumbers": queryMovements.numberPag,
            "projectId": queryMovements.projectId,
            "startDate": queryMovements.startDate,
            "endDate": queryMovements.endDate,
            "surrogacy": queryMovements.surrogacyFilter,
            "documentNumber": queryMovements.documentNum,
            "documentType": queryMovements.documentType
          }
        }
      }
    };
    return this.http.post(`${urlBase}/ch-ms-queries-constructor/movements`, JSON.stringify(requestData), { headers: this.httpHeaders });
  }
}
