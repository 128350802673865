import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { Output, EventEmitter } from '@angular/core';
import { AppState } from "../../app.reducers";
import { Store } from "@ngrx/store";
import { CompaniesBalance } from "../../shared/models/campanies.model";
import {
  SetIdDetailAction,
  Params,
} from "../../store/actions/shareparam.action";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { codeErrors, variables } from '../../shared/constants';

@Component({
  selector: "app-table",
  templateUrl: "./table.component.html",
  styleUrls: ["./table.component.scss"],
  providers: [Store],
})
export class TableComponent implements OnInit, OnDestroy {
  _campaniesBalance: Array<CompaniesBalance> = new Array();
  hover = new Array();
  hoveredOption = new Array();
  endAnimate = true;
  details: Array<any>;
  eventerror: any;
  suscription: Subscription;
  seeDetail = false;
  detailCreditData: any[] = [];
  isArrowDown = false;
  tryCount : number = 0;
  index : number = 0;


  @Output() isBreadCrumb = new EventEmitter<boolean>();
  @Input("route") route: string;
  constructor(private router: Router, public store: Store<AppState> ) {}

  ngOnDestroy() {
    for (let i = 0; i < this._campaniesBalance.length; i++) {
      this._campaniesBalance[i].Show = false;
      this._campaniesBalance[i].errorApi = false;
     }
  }

  ngOnInit() {
    this.suscription = this.store.select("queryState").subscribe((response) => {
      if (response) {
        this._campaniesBalance = response.query.map(obj => ({...obj}));
        if (response.message.status && this._campaniesBalance.length === 0) {
          this.endAnimate = true;
          this.router.navigate(["notificacion"]);
        } else {
          if (this._campaniesBalance.length !== 0) {
            this.endAnimate = false;
          }
        }
      }
    });
  }

  getActionLink(id: any, type: any) {
    const accion = new SetIdDetailAction(new Params(id, type));
    this.suscription.unsubscribe();
    this.store.dispatch(accion);
  }

  collapse(id: any, type: any, i: any) {
    if(this.tryCount <= 3){
      const element: any = document.getElementById(id);
      const isMore: any = element.getElementsByClassName("isCredits")[0];
      const header: any = element.getElementsByClassName("header")[0];
      const btnArrow: any = element.getElementsByClassName("btn-arrow")[0];

       if (this._campaniesBalance[i].Show || this._campaniesBalance[i].Empty) {
          isMore.innerHTML = '<label class="borderBottom cursorPointer ">Ver más</label>';
          this._campaniesBalance[i].Show = false;
          if (this._campaniesBalance[i].Empty) {
            this._campaniesBalance[i].Empty = false;
          }
          header.style.backgroundColor = "white"; //cuando se cierra el accordion
          header.style.color = "black";
          btnArrow.innerHTML = '<img class="arrowNew" src="../../../assets/icons/arrowDown.svg" alt="arrowDown" />'
          this.isArrowDown = false;
       } else {
          this.isArrowDown = true;
          header.style.backgroundColor = "black"; //cuando se abre el accordion
          btnArrow.innerHTML = '<img class="arrowNew" src="../../../assets/icons/arrowUp.svg" alt="arrowDown" />'
          header.style.color = "white";
          this.getActionLink(id, type);
          this._campaniesBalance[i].Empty = false;
          this._campaniesBalance[i].Show = true;
          this._campaniesBalance[i].errorApi = false;
        }
      this.details = i;
      this.index = i;
    }
  }

  public empty(mgsempty: any, id: any) {
    const errorResponse = mgsempty.message.error.errors[0];
    if(errorResponse.code == variables.code2 && errorResponse.status == codeErrors.code409){
        this.tryCount  = this.tryCount == variables.code4 ? 0 : this.tryCount += 1;
        this._campaniesBalance[mgsempty.position].errorApi = true;
        this._campaniesBalance[mgsempty.position].Show = false;
        this.eventerror = mgsempty.message.error.errors[0].detail;
    } else {
        const element: any = document.getElementById(id);
        const isMore: any = element.getElementsByClassName("isCredits")[0];
        this._campaniesBalance[mgsempty.position].errorApi = false;
        this._campaniesBalance[mgsempty.position].Empty = true;
        this._campaniesBalance[mgsempty.position].Show = false;
        this.eventerror = mgsempty.message.error.errors[0].detail;
        isMore.innerHTML =
          '<label class="cursorPointer buttonClose">Ver menos</label>';
    }
  }

  public validateBtnPager(pager: any, id: any) {
    const element: any = document.getElementById(id);
    const isMore: any = element.getElementsByClassName("isCredits")[0];
    if (pager == "Y") {
      isMore.style.display = "none";
    } else {
      isMore.style.display = "block";
      isMore.innerHTML =
        ' <label class="cursorPointer buttonClose">Ver menos</label>';
    }
  }

  irInicio(){
    this.callback();
  }

  public callback() {
    const elemento = document.getElementById("contenedor");
    elemento.style.display = "block";
    this.seeDetail = false;
    this.isBreadCrumb.emit(true);
  }

  public callDetailScream(seeDataDetail: any) {
    this.detailCreditData = seeDataDetail;
    const elemento = document.getElementById("contenedor");
    elemento.style.display = "none";
    this.seeDetail = true;
    this.isBreadCrumb.emit(false);
  }

  public newQueryBalance(id, type) {
    this.getActionLink(id, type);
  }

  reloadApi() : void {
    if(this.tryCount > 0 && this.tryCount <= 3){
      this._campaniesBalance[this.index].Show = false;
      this._campaniesBalance[this.index].Show = true;
      this._campaniesBalance[this.index].errorApi = false;
    }
  }
}
