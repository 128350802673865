import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader-blur',
  templateUrl: './loader-blur.component.html',
  styleUrls: ['./loader-blur.component.css']
})
export class LoaderBlurComponent implements OnInit {
  /**
   * Tamaño del componente.
   *
   * @type {string}
   * @memberof BcLoaderComponent
   */
   @Input() sizeLoader = 'md';
   /**
    * Color del elemento.
    *
    * @type {string}
    * @memberof BcLoaderComponent
    */
   @Input() colorLoader = 'default';
   /**
    * Velocidad.
    *
    * @type {string}
    * @memberof BcLoaderComponent
    */
   @Input() speedLoader = '0.5';
  constructor() { }

  ngOnInit(): void {
  }

}
