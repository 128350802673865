import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { PaymentData } from '../../shared/models/payment';
import { PaymentsService } from '../services/payments.service';
import { Store } from "@ngrx/store";

const MAX_TRY = 2;
@Component({
  selector: 'app-verify-data',
  templateUrl: './verify-data.component.html',
  styleUrls: ['./verify-data.component.scss']
})
export class VerifyDataComponent implements OnInit {
  dataPayment: any = {};
  paymentModel = new PaymentData();
  @Input() paymentobj: any = {};
  @Input() stateChild: any
  @Output() isVerifyModal = new EventEmitter<boolean>();
  @Output() returnInfo = new EventEmitter<boolean>();
  isConfirmPayment = false;
  stepsArray: string[] = ["Datos del pago", "Verificar"];
  direction: string = "horizontal";
  indexPosition = 1;
  myDate = new Date();
  loader = false;
  countTryValidatePayment = 0;
  isError = false;
  codeError;
  constructor(
    public paymentService: PaymentsService,
    public router: Router,
    private store: Store<{ shareparams: any }>,
    ) {
  }



  ngOnInit(): void {
    this.dataPayment = this.paymentobj;
  }

  paymentProcess() {

    this.loader = true;
    this.isError = false;
    this.paymentService.paymentCredit(this.dataPayment)
    .subscribe(
      (res: any) => {
        this.paymentobj = {
          ...this.paymentobj,
          'error': false,
          'titulo': 'Pago en proceso',
          'icon': '../../../assets/icons/Success.svg',
          'iconAlert': '../../../assets/icons/alertIonYe.svg',
          'ip': res.data.attributes.ip,
          'obligation': res.data.attributes.result.obligation.projectId,
          'paymentInformation': {

            'amount': res.data.attributes.result.paymentInformation.amount,
            'consecutive': res.data.attributes.result.paymentInformation.consecutive,
            'date': res.data.attributes.result.paymentInformation.date

          }
        }
        this.isConfirmPayment = true;
        this.loader = false;
        this.countTryValidatePayment = 0;
        this.isVerifyModal.emit(false);
      },
      (error: any) => {
        this.codeError = error.error.errors[0].code;
        switch (this.codeError) {
          case 'CTB001':
            this.loader = false;
            this.isConfirmPayment = true;
            this.paymentobj = {
              ...this.paymentobj,
              'consecutive': error.error.errors[0].consecutive,
              'error': true,
              'titulo': 'Pago rechazado',
              'tipoError': 'fondosInsuficientes',
              'icon': '../../../assets/icons/alert-error.svg',
              'iconAlert': '../../../assets/icons/alert-error.svg'
            };
            break;

          case 'CTB003':
            this.loader = false;
            this.isConfirmPayment = true;
            this.paymentobj = {
              ...this.paymentobj,
              'error': true,
              'titulo': 'Pago rechazado',
              'tipoError': 'ErrorGenerico',
              'icon': '../../../assets/icons/alert-error.svg',
              'iconAlert': '../../../assets/icons/alert-error.svg'
            };
            break;

          default:
            this.loader = false;
            this.isConfirmPayment = true;
            this.paymentobj = {
              ...this.paymentobj,
              'error': true,
              'titulo': 'Pago rechazado',
              'tipoError': 'ErrorGenerico',
              'icon': '../../../assets/icons/alert-error.svg',
              'iconAlert': '../../../assets/icons/alert-error.svg'
            }
            break;
        }
      }
    );
  }



  volver(){
    this.returnInfo.emit(false);
  }

}
