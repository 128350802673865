
// export interface AccountDetails{
//   tittle: '',
//   value: '',
//   projectId?: string,
//   projectName?: string,
//   ownerName?: string,
//   quarterlyInterest: Amount,
//   pendingBalance: Amount,
//   approvedAmount ?: string,
//   dueDate ?: string,
//   nextPaymentDate ?: string,
//   capitalBalance ?: string,
//   currentInterestBalance ?: string,
//   arrearsInterestBalance ?: string,

// }


export class AccountDetails {

  constructor(
    public tittle?: string,
    public value?: string,
    public projectId?: string,
    public projectName?: string,
    public ownerName?: string,
    public quarterlyInterest = new Amount(),
    public pendingBalance = new Amount(),
    public approvedAmount?: string,
    public dueDate?: string,
    public nextPaymentDate?: string,
    public capitalBalance?: string,
    public currentInterestBalance?: string,
    public arrearsInterestBalance?: string,
  ) {
  }

}
export class Amount {
  constructor(
    public currencyCode?: string,
    public amount?: string,
  ) {
  }
}

export const arrProjectsMock: AccountDetails[] = [
  {
    tittle: '',
    value: '',
    projectId: '134',
    projectName: 'MASK PROJ NAME 1',
    ownerName: 'NAME',
    quarterlyInterest: new Amount(),
    pendingBalance: new Amount(),
    approvedAmount: '5506000000',
    dueDate: '20180317',
    nextPaymentDate: '20190428',
    capitalBalance: '200000000.00',
    currentInterestBalance: '100000000.00',
    arrearsInterestBalance: '100000000.00'
  }
];
export const accountDetailsMok: AccountDetails =
{
  tittle: '',
  value: '',
  projectId: '134',
  projectName: 'MASK PROJ NAME 1',
  ownerName: 'NAME',
  quarterlyInterest: new Amount(),
  pendingBalance: new Amount(),
  approvedAmount: '5506000000',
  dueDate: '20180317',
  nextPaymentDate: '20190428',
  capitalBalance: '200000000.00',
  currentInterestBalance: '100000000.00',
  arrearsInterestBalance: '100000000.00'
};
/**
 *  {
            "projectId": "134",
            "projectName": "MASK PROJ NAME 1",
            "ownerName": "NAME",
            "quarterlyInterest": {
              "currencyCode": "*",
              "amount": "0"
            },
            "pendingBalance": {
              "currencyCode": "*",
              "amount": "5794931356.73"
            },
            "approvedAmount": "5506000000",
            "dueDate": "20180317",
            "nextPaymentDate": "20190428",
            "capitalBalance": "200000000.00",
            "currentInterestBalance": "100000000.00",
            "arrearsInterestBalance": "50000000.00"
          }
 */


