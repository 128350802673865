import { Component, OnInit } from '@angular/core';
import { PaymentsService } from '../../../services/payments.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-verify-obligation-payment',
  templateUrl: './verify-obligation-payment.component.html',
  styleUrls: ['./verify-obligation-payment.component.css']
})
export class VerifyObligationPaymentComponent implements OnInit {

  data: any;
  objForPaymentObligation: any;
  loader = false;
  codeError;
  paymentobj: any;

  constructor(public paymentService: PaymentsService, private router: Router) { }

  ngOnInit(): void {
    this.loadingInfo();
  }

  loadingInfo() {
    this.data = this.paymentService.getObligationPayment;
  }

  goToBack() {
    this.router.navigateByUrl('pagos/operaciones');
  }

  converTypeAccount(typeAccount): string {
    return (typeAccount === 'Cuenta de ahorros') ? 'CUENTA_DE_AHORRO' : 'CUENTA_CORRIENTE';
  }



  goToPayment() {
    // this.paymentService.resetInfoPaid();
    this.loader = true;
    if (this.data.value.typeSelected === "1") {
      this.objForPaymentObligation = {
        'typePay': 'CT',
        'amountTransfer': this.data.value.totalPayment,
        'projectId': this.data.value.projectNumber,
        'obligationId': this.data.value.obligation,
        'typeAccount': this.converTypeAccount(this.data.value.typeAccount),
        'number': this.data.value.account.replace(/-/g, '')
      }
    } else {
      this.objForPaymentObligation = {
        'typePay': 'P',
        'amountTransfer': this.data.value.otherAmount.replace(/\./g, ''),
        'projectId': this.data.value.projectNumber,
        'obligationId': this.data.value.obligation,
        'typeAccount': this.converTypeAccount(this.data.value.typeAccount),
        'number': this.data.value.account.replace(/-/g, '')
      }
    }
    this.paymentService.paymentObligation(this.objForPaymentObligation).subscribe(res => {
      this.loader = false;
      this.paymentobj = {
        'error': false,
        'titulo': 'Pago exitoso',
        'icon': '../../../assets/icons/Success.svg',
        'iconAlert': '../../../assets/icons/alertIonYe.svg',
        'amount': res.data.attributes.result.paymentInformation.amount,
        'consecutive': res.data.attributes.result.paymentInformation.consecutive,
        'date': res.data.attributes.result.paymentInformation.date
      };
      this.paymentService.savedWhenPaidObligation(this.paymentobj);
      this.router.navigateByUrl('pagos/confirmPaymentObligation');
    },
      (error: any) => {
        this.codeError = error.error.errors[0].code;
        switch (this.codeError) {
          case 'CTB001':
            this.loader = false;
            this.paymentobj = {
              'consecutive': error.error.errors[0].consecutive,
              'error': true,
              'titulo': 'Pago rechazado',
              'tipoError': 'fondosInsuficientes',
              'icon': '../../../assets/icons/alert-error.svg',
              'iconAlert': '../../../assets/icons/alert-error.svg'
            };
            this.paymentService.savedWhenPaidObligation(this.paymentobj);
            break;

          case 'CTB003':
            this.loader = false;
            this.paymentobj = {
              'error': true,
              'titulo': 'Pago rechazado',
              'tipoError': 'ErrorGenerico',
              'icon': '../../../assets/icons/alert-error.svg',
              'iconAlert': '../../../assets/icons/alert-error.svg'
            };
            this.paymentService.savedWhenPaidObligation(this.paymentobj);
            break;

          default:
            this.loader = false;
            this.paymentobj = {
              'error': true,
              'titulo': 'Pago rechazado',
              'tipoError': 'ErrorGenerico',
              'icon': '../../../assets/icons/alert-error.svg',
              'iconAlert': '../../../assets/icons/alert-error.svg'
            }
            this.paymentService.savedWhenPaidObligation(this.paymentobj);
            break;
        }
        this.router.navigateByUrl('pagos/confirmPaymentObligation');
      }
    )
  }

}
