export const PATH_FONTS = {
  CIB_FONT_SANS_BOLD: 'assets/fonts/CIBFontSans-Bold.ttf',
  CIB_FONT_SANS_LIGHT: 'assets/fonts/CIBFontSans-Light.ttf',
  OPEN_SANS_REGULAR: 'assets/fonts/OpenSans-Regular.ttf',
  OPEN_SANS_SEMIBOLD: 'assets/fonts/OpenSans-SemiBold.ttf',
}

export const BANCOLOMBIA_ICON_PDF = {
  src: '/assets/images/bancolombia-horizontal.png',
  x: 20,
  y: 24,
  width: 240,
  height: 70,
};

export const STROKE_ICON_PDF = {
  src: '/assets/images/stroke-12.png',
  x: 440,
  y: 0,
  width: 380,
  height: 130,
};

export const SVN_TEXT_PDF = {
  x: 32,
  y: 150,
  text: 'Sucursal Virtual Negocios',
};

export enum FONT_TYPES {
  CIB_BOLD = 0,
  CIB_LIGHT = 1,
  OPEN_SANS_REGULAR = 2,
  OPEN_SANS_SEMIBOLD = 3,
}
