import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyFormat'
})
export class CurrencyFormatPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value !== null && value != undefined) {
      let result = (value.toString().indexOf('.') !== -1) ?
      value.toString().substring(0, value.toString().indexOf('.')) : value;
      if (result === '') {
        result = result + 0;
      }
      result = '$ ' + result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      result = result + ',' + ((value.toString().indexOf('.') !== -1) ?
      value.toString().substring(value.toString().indexOf('.') + 1, value.length) : '00');
      return result;
    } else {
      return '$ ' + '0,00';
    }
  }
}
