import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotifyComponent } from './notify/notify.component';
import { QueryBalanceComponent } from '../query/components/query-balance/query-balance.component';
import { SomethingWrongComponent } from './something-wrong/something-wrong.component';

const routes: Routes = [
  { path: 'consulta', component: QueryBalanceComponent },
  { path: 'notificacion', component: NotifyComponent },
  { path: 'pagos', loadChildren: () => import('../payments/payments.module').then(m => m.PaymentsModule) },
  { path: 'error', component: SomethingWrongComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class GenericRoutingModule { }
