export const variables = {
    code0 : 0,
    code1 : 1,
    code2 : 2,
    code3 : 3,
    code4 : 4,
    code5 : 5,
    code6 : 6,
    code7 : 7,
    code8 : 8,
    code9 : 9,
    code10 : 10
};

export const codeErrors = {
  code400: 400,
  code401: 401,
  code406: 406,
  code404: 404,
  code409: 409,
  code500: 500
};

export const numberRepeatLines = [0,1,variables.code2];
