import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BcStepperComponent } from '@bancolombia/design-system-web/bc-stepper';

@Component({
  selector: 'stepper-obligation',
  templateUrl: './stepper-obligation.component.html',
  styleUrls: ['./stepper-obligation.component.css']
})
export class StepperObligationComponent implements OnInit {

  @ViewChild('stepper1') stepper1!: BcStepperComponent;
  @Input() positionStep!: number;
  stepsArray: string[] = ["Datos del pago", "Verificar"];
  constructor() { }

  ngOnInit(): void {
  }

}
