import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PaymentsService } from '../../../services/payments.service';
import { GeneratePdfService } from '../../../services/pdf/generate-pdf.service';



@Component({
  selector: 'app-confirm-payment-obligation',
  templateUrl: './confirm-payment-obligation.component.html',
  styleUrls: ['./confirm-payment-obligation.component.css']
})
export class ConfirmPaymentObligationComponent implements OnInit {

  data: any;
  objPdf: any;
  myDate = new Date();
  fechaActual = new Date();
  comprobante = null;
  savedWhenPaymentObligation:any = null;



  constructor(private paymentService: PaymentsService, private router: Router, private pdfService: GeneratePdfService) { }

  ngOnInit(): void {
    this.loadingInfo();
  }

  loadingInfo() {
    this.data = this.paymentService.getPaidObligationPayment;
    this.savedWhenPaymentObligation = this.paymentService.getWhenPaidObligation;
  }


  formatNumber(number: string) {
    let numberStr = number.toString();
    if (numberStr.indexOf('.') === -1) {
      // No decimal point, add ",00" for cents
      numberStr += ",00";
    } else {
      // Replace the decimal point with a comma
      numberStr = numberStr.replace('.', ',');
    }
    let parts = numberStr.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return parts.join(",");
  }

  descargarComprobante() {
    const opciones: any = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true
    };
    const fecha = new Date();
    const fechaFormateada = fecha.toLocaleString('es-ES', opciones);

    this.objPdf = {
      comprobante: this.savedWhenPaymentObligation.consecutive,
      titulo: 'Pago por obligación',
      fecha: fechaFormateada,
      nombreProyecto: this.data.value.projectName,
      numeroProyecto: this.data.value.projectNumber,
      numeroObligacion: this.data.value.obligation,
      totalPagar: this.formatNumber(this.data.value.amount),
      tipoCuenta: this.data.value.typeAccount,
      numeroCuenta: this.data.value.account
    }

    this.pdfService.generarPdf(this.objPdf);

  }



}
