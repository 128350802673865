import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app.reducers';
import { GetItemsQuery } from '../../../store/actions/query.actions';
import { QueryEffects } from '../../../store/effects/query.effects';
import { CompaniesBalance } from '../../../shared/models/campanies.model';
import { take, takeWhile } from 'rxjs/operators';



@Component({
  selector: 'app-query-balance',
  templateUrl: './query-balance.component.html',
  styleUrls: ['./query-balance.component.scss']
})
export class QueryBalanceComponent implements OnInit {

  isBreadCrumb: boolean = true;
  _campaniesBalance: Array<CompaniesBalance> = new Array();
  constructor(private store: Store<AppState>, private queryEffects: QueryEffects) {}

  ngOnInit() {
    this.store.select('shareparams')
    .pipe(takeWhile((r: any) => r.dataapp && r.dataapp.jwt),
    take(1))
    .subscribe(result => {
      this.store.dispatch(new GetItemsQuery(this._campaniesBalance, result.dataapp));
    });
  }
  public bredCrumbHide(value: boolean) {
    this.isBreadCrumb = value;
  }
}

